import * as React from 'react'
import { Box, Stepper, Step, StepLabel, Button } from '@mui/material'

type StepProp = {
  title: string
  component: React.ReactNode
  id: string
}

type Props = {
  activeStep: number
  setActiveStep: (activeStep: number) => void
  steps: StepProp[]
  customHandleNext?: () => void
  customHandleBack?: () => void
  lastStepButtonName?: string
  disabledLastStepButton?: boolean
}

const HorizontalLinearStepper: React.FC<Props> = ({
  activeStep,
  setActiveStep,
  steps,
  customHandleNext,
  customHandleBack,
  lastStepButtonName = 'Complete',
  disabledLastStepButton = false,
}) => {
  const handleNext = (step: number) => {
    if (customHandleNext) {
      customHandleNext()
    } else {
      setActiveStep(step + 1)
    }
  }

  const handleBack = (step: number) => {
    if (customHandleBack) {
      customHandleBack()
    } else {
      setActiveStep(step - 1)
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((step) => {
          const stepProps: { completed?: boolean } = {}
          const labelProps: {
            optional?: React.ReactNode
          } = {}
          return (
            <Step key={step.id} id={step.id} {...stepProps}>
              <StepLabel {...labelProps}>{step.title}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Button
          color='inherit'
          disabled={activeStep === 0}
          onClick={() => handleBack(activeStep)}
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button
          onClick={() => handleNext(activeStep)}
          disabled={
            disabledLastStepButton ? activeStep === steps.length - 1 : false
          }
        >
          {activeStep === steps.length - 1 ? lastStepButtonName : 'Next'}
        </Button>
      </Box>
    </Box>
  )
}

export default HorizontalLinearStepper
