import { TablePagination, Typography } from '@mui/material'
import { PatientClassWithPatientsResponse, TargetData } from '../types'

type PageDirection = 'DIRECTION_NEXT' | 'DIRECTION_BACK'
export const defaultPageSize = 10

type Props = {
  pageIndex: number
  sampleCount: number
  selectedSampleCount: number
  samplesData: PatientClassWithPatientsResponse | undefined
  pageSize: number
  setPageSize: React.Dispatch<React.SetStateAction<number>>
  setShowSelectAllMessage: (value: React.SetStateAction<boolean>) => void
  setSelectAll: (value: React.SetStateAction<boolean>) => void
  setPagination: (value: React.SetStateAction<TargetData>) => void
  setPageIndex: (value: React.SetStateAction<number>) => void
}

export const Footer: React.FC<Props> = ({
  pageIndex,
  sampleCount,
  selectedSampleCount,
  samplesData,
  pageSize,
  setPageSize,
  setShowSelectAllMessage,
  setSelectAll,
  setPagination,
  setPageIndex,
}) => {
  const handlePageChange = (direction: PageDirection) => {
    if (!samplesData) {
      return
    }

    setSelectAll(false)
    setShowSelectAllMessage(false)

    let newPageIndexAddition = 1
    let nextId: number | null = null
    let prevId: number | null = null

    if (
      direction === 'DIRECTION_NEXT' &&
      samplesData?.has_more &&
      samplesData.patients_with_sample_group.length > 0
    ) {
      nextId = samplesData.next_cursor
    } else if (direction === 'DIRECTION_BACK' && samplesData.has_previous) {
      newPageIndexAddition = -1
      prevId = samplesData.prev_cursor
    }

    setPagination((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        lastId: nextId,
        prevId: prevId,
      },
    }))
    setPageIndex((prevPageIndex) => prevPageIndex + newPageIndexAddition)
  }

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newPageSize = parseInt(event.target.value, 10)

    setPageSize(newPageSize)
    setPageIndex(0)
    setPagination((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        lastId: null,
        prevId: null,
        pageSize: newPageSize,
      },
    }))
  }

  return (
    <div className='table-footer'>
      <div className='navigation'>
        <Typography className='selected-samples' variant='body2'>
          Selected Samples: {selectedSampleCount}
        </Typography>
        <TablePagination
          rowsPerPageOptions={[10, 15, 20, 25, 30]}
          component='div'
          count={sampleCount}
          rowsPerPage={pageSize}
          page={pageIndex}
          onPageChange={(event, newPage) => {
            // Determine the direction of navigation (forward/backward)
            if (newPage > pageIndex) {
              handlePageChange('DIRECTION_NEXT')
            } else {
              handlePageChange('DIRECTION_BACK')
            }
          }}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </div>
    </div>
  )
}
