export function flattenObject(
  obj: Record<string, unknown>,
  parentKey = '',
  res: Record<string, unknown> = {}
): Record<string, unknown> {
  if (Array.isArray(obj)) {
    obj.forEach((item, index) => {
      const arrayKey = parentKey
        ? `${parentKey} ${index + 1}`
        : `Item ${index + 1}`
      if (typeof item === 'object' && item !== null) {
        flattenObject(item as Record<string, unknown>, arrayKey, res)
      } else {
        res[arrayKey] = item
      }
    })
  } else if (typeof obj === 'object' && obj !== null) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const propName = parentKey ? `${parentKey} > ${key}` : key
        flattenObject(obj[key] as Record<string, unknown>, propName, res)
      }
    }
  } else {
    res[parentKey] = obj
  }
  return res
}

// function flattenObject(obj: unknown, parentKey = '', res: Record<string, unknown> = {}): Record<string, unknown> {
//     if (Array.isArray(obj)) {
//       obj.forEach((item, index) => {
//         const arrayKey = parentKey ? `${parentKey} ${index + 1}` : `Item ${index + 1}`
//         if (typeof item === 'object' && item !== null) {
//           flattenObject(item, arrayKey, res)
//         } else {
//           res[arrayKey] = item
//         }
//       })
//     } else if (typeof obj === 'object' && obj !== null) {
//       for (const key in obj) {
//         if (Object.prototype.hasOwnProperty.call(obj, key)) {
//           const propName = parentKey ? `${parentKey} > ${key}` : key
//           flattenObject((obj as Record<string, unknown>)[key], propName, res)
//         }
//       }
//     } else {
//       res[parentKey] = obj
//     }
//     return res
//   }
