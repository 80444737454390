import React from 'react'

import { IconButton } from '@mui/material'
import { iconsObj } from '../../icons/Icons'

interface RedirectToUrlButtonProps {
  url: string
}

const RedirectToUrlButton: React.FC<RedirectToUrlButtonProps> = ({ url }) => {
  const redirectToUrl = () => {
    const fullUrl =
      url.startsWith('http://') || url.startsWith('https://')
        ? url
        : `https://${url}`
    window.open(fullUrl, '_blank', 'noopener,noreferrer')
  }

  return (
    <IconButton size='small' onClick={redirectToUrl}>
      {iconsObj.OPEN}
    </IconButton>
  )
}

export default RedirectToUrlButton
