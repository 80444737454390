import React, { useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Typography,
  IconButton,
  Stack,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useCreatePatientClass } from '../hooks'
import { LoadingButton } from '@mui/lab'
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder'

interface AddPatientClassDialogProps {
  targetManagerId: number
}

const AddPatientClassDialog: React.FC<AddPatientClassDialogProps> = ({
  targetManagerId,
}) => {
  const [open, setOpen] = useState(false)
  const [className, setClassName] = useState('')
  const createPatientClassMutation = useCreatePatientClass(targetManagerId)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setClassName('')
  }

  const handleConfirmAdd = () => {
    if (className.trim()) {
      createPatientClassMutation.mutate(
        { targetDataManagerId: targetManagerId, className: className },
        {
          onSuccess: () => {
            handleClose()
          },
        }
      )
    }
  }

  return (
    <Stack direction={'row'} style={{ alignItems: 'center' }}>
      <Stack direction='row' style={{ alignItems: 'center' }}>
        <Typography variant='body1' align='left' ml={0.5}>
          Classified Samples
        </Typography>
        <IconButton aria-label='add' color='primary' onClick={handleClickOpen}>
          <AddIcon />
        </IconButton>
      </Stack>

      {/* Dialog that adds a new Patient Class to the selected Target Data Manager */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create New Patient Class</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin='dense'
            label='Class Name'
            type='text'
            fullWidth
            value={className}
            onChange={(e) => setClassName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <LoadingButton
            onClick={handleConfirmAdd}
            color='primary'
            disabled={!className.trim()}
            loading={createPatientClassMutation.isPending}
            variant='outlined'
            loadingPosition='end'
            endIcon={<CreateNewFolderIcon />}
          >
            Create
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Stack>
  )
}

export default AddPatientClassDialog
