import React from 'react'
import { Controller } from 'react-hook-form'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { UseAutocompleteProps } from '@mui/material'

import FormProps, { Option } from './FormProps'

type FormInputComboBoxProps = FormProps &
  UseAutocompleteProps<Option, boolean, false, false> & {
    options: Option[]
    variant?: 'filled' | 'outlined' | 'standard'
    size?: 'small' | 'medium'
    required?: boolean
    keepSelectedOption?: boolean
  }

const FormInputComboBox: React.FC<FormInputComboBoxProps> = ({
  name,
  control,
  label,
  required,
  keepSelectedOption = false,
  options = [],
  variant = 'outlined',
  multiple = false,
  size = 'small',
  ...props
}) => {
  // define validation rules
  const ruleRequired = required ? { required: 'Field is required!' } : {}

  return (
    <Controller
      name={name}
      control={control}
      rules={ruleRequired}
      defaultValue={multiple ? [] : null}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Autocomplete
          {...props}
          fullWidth
          multiple={multiple}
          id={`${name}-select`}
          options={
            keepSelectedOption && !multiple ? [value, ...options] : options
          }
          getOptionDisabled={(option: Option | null) =>
            option?.disabled ?? false
          }
          value={value}
          defaultValue={multiple ? [] : null}
          onChange={(event, data, reason, details) => {
            onChange(data)
            props.onChange?.(event, data, reason, details)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!error}
              helperText={error ? error.message : null}
              variant={variant}
              label={label}
              autoComplete='disabled'
              size={size}
              required={required}
            />
          )}
          getOptionLabel={(option: Option | null) => option?.label ?? ''}
          isOptionEqualToValue={(option: Option, value: Option) =>
            option.id === value.id
          }
        />
      )}
    />
  )
}

export default FormInputComboBox
