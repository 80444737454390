import Button, { ButtonProps } from '@mui/material/Button'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import Tooltip, { TooltipProps } from '@mui/material/Tooltip'

import React from 'react'

type FullButton = {
  iconButton?: false
  buttonProps: ButtonProps
  tooltipProps: Omit<TooltipProps, 'children'>
}

type FullIconButton = {
  iconButton: true
  buttonProps: IconButtonProps
  tooltipProps: Omit<TooltipProps, 'children'>
}

type FullButtonProps = FullButton | FullIconButton

type FullProps = FullButtonProps & {
  children: React.ReactNode
}

type Props = ButtonProps & {
  tooltipText?: TooltipProps['title']
  tooltipPlacement?: TooltipProps['placement']
  tooltipId?: TooltipProps['id']
}

type IconProps = IconButtonProps & {
  tooltipText?: TooltipProps['title']
  tooltipPlacement?: TooltipProps['placement']
  tooltipId?: TooltipProps['id']
}

// this button is able to process tooltip even in disabled state
// we should use it anywhere instead of Button
export const FullTooltipButton: React.FC<FullProps> = ({
  buttonProps,
  tooltipProps,
  iconButton = false,
  children,
}) => {
  const adjustedButtonProps = {
    component: buttonProps.disabled ? '' : undefined,
    onClick: buttonProps.disabled ? undefined : buttonProps.onClick,
  }

  return (
    <Tooltip {...tooltipProps}>
      {iconButton ? (
        <IconButton
          {...(buttonProps as IconButtonProps)}
          sx={{
            '&.Mui-disabled': {
              pointerEvents: 'auto',
            },
            ...buttonProps.sx,
          }}
          {...adjustedButtonProps}
        >
          {children}
        </IconButton>
      ) : (
        <Button
          {...(buttonProps as ButtonProps)}
          sx={{
            '&.Mui-disabled': {
              pointerEvents: 'auto',
            },
            ...buttonProps.sx,
          }}
          {...adjustedButtonProps}
        >
          {children}
        </Button>
      )}
    </Tooltip>
  )
}

const TooltipButton: React.FC<Props> = ({
  tooltipText,
  tooltipPlacement,
  tooltipId,
  children,
  ...buttonProps
}) => {
  return (
    <FullTooltipButton
      buttonProps={buttonProps}
      tooltipProps={{
        title: tooltipText,
        placement: tooltipPlacement,
        id: tooltipId,
      }}
    >
      {children}
    </FullTooltipButton>
  )
}

export const TooltipIconButton: React.FC<IconProps> = ({
  tooltipText,
  tooltipPlacement,
  tooltipId,
  children,
  ...buttonProps
}) => {
  return (
    <FullTooltipButton
      buttonProps={buttonProps}
      tooltipProps={{
        title: tooltipText,
        placement: tooltipPlacement,
        id: tooltipId,
      }}
      iconButton
    >
      {children}
    </FullTooltipButton>
  )
}

export default TooltipButton
