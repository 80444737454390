import { Autocomplete, TextField } from '@mui/material'
import { useSampleGroups } from '../hooks'
import { FilterOptions, SampleGroupResponse, TargetData } from '../types'
import { Table } from '@tanstack/react-table'
import { Patient } from '../../../../TargetDataManager/types'
import { MetadataFilters } from './MetadataFilter'

type Props = {
  table: Table<Patient>
  setPagination: (value: React.SetStateAction<TargetData>) => void
  setPageIndex: (value: React.SetStateAction<number>) => void
  setSelectAll: (value: React.SetStateAction<boolean>) => void
  handleFilterchange(
    updatedFilters: Partial<FilterOptions>,
    resetPage?: boolean
  ): void
}

export const TableFilters: React.FC<Props> = ({
  table,
  setPagination,
  setPageIndex,
  setSelectAll,
  handleFilterchange,
}) => {
  const sampleGroups = useSampleGroups()

  return (
    <div className='filter-container'>
      {/* Filter By Sample Name */}
      <TextField
        label='Sample Name'
        value={
          (table.getColumn('sample_name')?.getFilterValue() ?? '') as string
        }
        onChange={(e) => {
          const newValue = e.target.value

          setPagination((prevPagination) => ({
            pagination: {
              ...prevPagination.pagination,
              prevId: null,
              lastId: null,
            },
            filters: {
              ...prevPagination.filters,
              sample_name: newValue.trim() !== '' ? newValue : null,
            },
          }))
          setPageIndex(0)
          return table.getColumn('sample_name')?.setFilterValue(e.target.value)
        }}
      />
      {/* Filter By Sample Group */}
      <Autocomplete
        sx={{ minWidth: '200px' }}
        options={sampleGroups.data ?? []}
        getOptionLabel={(option: SampleGroupResponse) => option.name || ''}
        // value={table.getColumn('sample_group')?.getFilterValue()}
        onInputChange={(event, newInputValue) => {
          // Deactivate the global checkbox
          if (newInputValue.trim() !== '') {
            setSelectAll(false)
          }

          // Update pagination and filters
          handleFilterchange(
            {
              group_name: newInputValue.trim() !== '' ? newInputValue : null,
            },
            true
          )
          setPageIndex(0)
          table.getColumn('sample_group')?.setFilterValue(newInputValue)
        }}
        onChange={(event, newValue) => {
          // Handle selection from dropdown
          const newGroup = newValue?.name || ''
          handleFilterchange(
            {
              group_name: newGroup || null,
            },
            true
          )

          setPageIndex(0)
          table.getColumn('sample_group')?.setFilterValue(newGroup)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Sample Group'
            variant='outlined'
            placeholder='Select or search group'
          />
        )}
      />
      <MetadataFilters handleFilterchange={handleFilterchange} />
      {/* <LoadingButton fullWidth loading variant='outlined'>
        Submit
      </LoadingButton> */}
    </div>
  )
}
