import { Typography } from '@mui/material'
import { flattenObject } from '../utils'

type Props = {
  patient_metadata: Record<string, unknown>
}

export const MetadataView: React.FC<Props> = ({ patient_metadata }) => {
  return (
    <div className='metadata-container'>
      <div className='metadata-wrapper'>
        {patient_metadata &&
          Object.entries(flattenObject(patient_metadata)).map(
            ([key, value], index) => (
              <Typography
                style={{
                  paddingBlock: '.5em',
                  paddingInlineStart: '.7em',
                  paddingInlineEnd: '.7em',
                  backgroundColor: 'white',
                  borderRadius: '5px',
                }}
                key={index}
                className='cell-value'
                variant='body2'
              >
                <strong>{key}:</strong>&nbsp;{String(value)}
              </Typography>
            )
          )}
      </div>
    </div>
  )
}
