import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import { usePatientMetadataDetail } from '../hooks'
import SubmitButton from '../../../../../components/buttons/SubmitButton'
import { iconsObj } from '../../../../../icons/Icons'
import { useState } from 'react'
import { MetadataView } from './MetadataView'

type Props = {
  patientId: number
}

export const PatientMetadataDetail: React.FC<Props> = ({ patientId }) => {
  const [open, setOpen] = useState(false)

  const patientMetadatadetail = usePatientMetadataDetail(patientId, open)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <>
      <Button
        sx={{ marginTop: '1.5rem', marginBottom: '.875rem' }}
        endIcon={iconsObj.OPEN}
        onClick={handleOpen}
        variant='contained'
      >
        Show More
      </Button>
      <Dialog
        fullWidth
        maxWidth='lg'
        open={open}
        onClose={handleClose}
        aria-labelledby='new-target-data-dialog'
        aria-describedby='new-target-data-dialog'
      >
        <DialogTitle>
          <Stack direction='row' spacing={1}>
            <Typography variant='h5'>
              <strong>Sample metadata</strong>:{' '}
              {patientMetadatadetail.data?.sample_name}
            </Typography>
          </Stack>
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          {iconsObj.CLOSE}
        </IconButton>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}
        >
          {patientMetadatadetail.data && (
            <MetadataView
              patient_metadata={patientMetadatadetail.data?.patient_metadata}
            />
          )}
        </DialogContent>
        <DialogActions
          sx={{ mx: 2.5, mb: 2, mt: 3, justifyContent: 'flex-end' }}
        >
          <SubmitButton
            id='create-new-target-data-btn'
            text='Close'
            fullWidth={false}
            onClick={handleClose}
          />
        </DialogActions>
      </Dialog>
    </>
  )
}
