import React, { useEffect } from 'react'
import NewBaseDataSkeleton from '../NewBaseDataSkeleton'
import { Control, UseFormReset, UseFormUnregister } from 'react-hook-form'
import { useAuth } from '../../context/AuthContext'
import { enqueueSnackbar } from 'notistack'
import FormInputCheckbox from '../forms/FormInputCheckbox'
import { PopulationFormInput } from '../dialogs/NewBaseDataDialog'

export type Population = {
  id: number
  population: string
}

export type ExtendedPopulation = Population & {
  isSelected: boolean
}

type Props = {
  subDiseaseIds: number[]
  population: ExtendedPopulation[]
  setPopulation: (population: ExtendedPopulation[]) => void
  hasSubDiseasesChanged: boolean
  setHasSubDiseasesChanged: (hasSubDiseasesChanged: boolean) => void
  setHasPopulationChanged: (hasPopulationChanged: boolean) => void
  unregister: UseFormUnregister<PopulationFormInput>
  reset: UseFormReset<PopulationFormInput>
  control: Control<PopulationFormInput>
}

const BaseDataPopulationStep: React.FC<Props> = (props) => {
  const { backendRequest, logout } = useAuth()

  useEffect(() => {
    if (props.hasSubDiseasesChanged) {
      const getData = async () => {
        const responsePopulaion = await backendRequest({
          method: 'POST',
          endpoint: '/api/gwas-variants/filter/population',
          requiresAuth: true,
          body: { sub_disease_ids: props.subDiseaseIds },
        })

        const populationData = responsePopulaion.data as
          | Population[]
          | undefined

        return { status: responsePopulaion.status, data: populationData }
      }
      getData()
        .then((response) => {
          if (response.status === 200 && response.data) {
            const populationData = response.data as Population[] | undefined
            const defaultValues = populationData?.reduce(
              (acc, { population }) => ({
                ...acc,
                [population]: false,
              }),
              {}
            )

            props.reset(defaultValues)
            props.setPopulation(
              populationData?.map((pop) => ({ ...pop, isSelected: false })) ||
                []
            )
          } else if (response.status === 401) {
            logout()
          }
        })
        .catch((error) => {
          enqueueSnackbar(`An error occurred. ${error.response?.data.detail}`, {
            variant: 'error',
          })
        })
        .finally(() => {
          props.setHasSubDiseasesChanged(false)
          props.setHasPopulationChanged(true)
        })
    }
  }, [])

  return (
    <NewBaseDataSkeleton
      title='Choose Population'
      children={
        <React.Fragment>
          {props.population.map((pop, index) => (
            <FormInputCheckbox
              key={index}
              name={pop.population}
              label={pop.population}
              value={false}
              control={props.control}
            />
          ))}
        </React.Fragment>
      }
    />
  )
}

export default BaseDataPopulationStep
