import React, { useMemo } from 'react'
import { FaSquare, FaCheckSquare, FaMinusSquare } from 'react-icons/fa'
import { IoMdArrowDropright } from 'react-icons/io'
import TreeView, { flattenTree } from 'react-accessible-treeview'
import cx from 'classnames'
import '../../src/styles.css'

type Props = {
  data: FolderNode
  selectedIds: number[]
  setSelectedIds: (selectedIds: number[]) => void
  selectedDbIds: number[]
  setSelectedDbIds: (selectedDbIds: number[]) => void
  setHasChanged: (hasChanged: boolean) => void
  forceRerender?: number
}

export type FolderNode = {
  name: string
  db_id: number
  id: number
  children?: FolderNode[]
}

type ArrowIconProps = {
  isOpen: boolean
  className?: string
}

type CheckBoxIconProps = {
  variant: 'all' | 'none' | 'some'
  onClick?: React.MouseEventHandler<SVGElement>
  className?: string
}

const ArrowIcon: React.FC<ArrowIconProps> = ({ isOpen, className }) => {
  const baseClass = 'arrow'
  const classes = cx(
    baseClass,
    { [`${baseClass}--closed`]: !isOpen },
    { [`${baseClass}--open`]: isOpen },
    className
  )
  return <IoMdArrowDropright className={classes} />
}

const CheckBoxIcon: React.FC<CheckBoxIconProps> = ({
  variant,
  className,
  ...rest
}) => {
  switch (variant) {
    case 'all':
      return <FaCheckSquare className={className} {...rest} />
    case 'none':
      return <FaSquare className={className} {...rest} />
    case 'some':
      return <FaMinusSquare className={className} {...rest} />
    default:
      return null
  }
}

export const dbIdsToIds = (node: FolderNode, dbIds: number[]): number[] => {
  const ids: number[] = []

  const findIds = (currentNode: FolderNode, targetDbIds: number[]) => {
    if (targetDbIds.includes(currentNode.db_id)) {
      ids.push(currentNode.id)
    }
    if (currentNode.children) {
      currentNode.children.forEach((child) => findIds(child, targetDbIds))
    }
  }

  findIds(node, dbIds)
  return ids
}

const MultiSelectCheckboxTreeView: React.FC<Props> = (props) => {
  const data = useMemo(() => {
    return {
      name: '',
      db_id: 0,
      id: 0,
      children: [props.data],
    }
  }, [props.data])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelectedItems = (node: any) => {
    const checkedIds: number[] = Array.from(node.treeState.selectedIds)

    const selectedDbIds: number[] = []

    // Recursive function to find and collect db_ids of selected nodes
    const findSelectedNodeDbIds = (node: FolderNode, selectedIds: number[]) => {
      if (selectedIds.includes(node.id)) {
        selectedDbIds.push(node.db_id)
      }
      if (node.children) {
        node.children.forEach((child) =>
          findSelectedNodeDbIds(child, selectedIds)
        )
      }
    }

    findSelectedNodeDbIds(props.data, checkedIds)

    props.setHasChanged(true)
    props.setSelectedDbIds(selectedDbIds)
    props.setSelectedIds(checkedIds)
  }

  return (
    props.data.children && (
      <TreeView
        key={props.forceRerender}
        data={flattenTree(data)}
        className='checkbox'
        aria-label='Checkbox tree'
        multiSelect
        propagateSelect
        propagateSelectUpwards
        togglableSelect
        defaultSelectedIds={props.selectedIds}
        onNodeSelect={handleSelectedItems}
        nodeRenderer={({
          element,
          isBranch,
          isExpanded,
          isSelected,
          isHalfSelected,
          getNodeProps,
          level,
          handleSelect,
          handleExpand,
        }) => {
          return (
            <div
              {...getNodeProps({ onClick: handleExpand })}
              style={{ marginLeft: 40 * (level - 1) }}
            >
              {isBranch && <ArrowIcon isOpen={isExpanded} />}
              <CheckBoxIcon
                className='checkbox-icon'
                onClick={(e) => {
                  handleSelect(e)
                  e.stopPropagation()
                }}
                variant={isHalfSelected ? 'some' : isSelected ? 'all' : 'none'}
              />
              <span className='name'>{element.name}</span>
            </div>
          )
        }}
      />
    )
  )
}

export default MultiSelectCheckboxTreeView
