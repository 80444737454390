import React, { useEffect, useState } from 'react'
import {
  Box,
  Drawer,
  Button,
  Typography,
  SelectChangeEvent,
  Switch,
  FormControl,
  FormLabel,
  FormGroup,
  Slider,
} from '@mui/material'
import { GeneType, EvidenceType } from '../pages/ProcessBasedAnalysis'
import MultipleSelectCheckmarks from './MultipleSelectCheckmarks'
import FormControlLabel from '@mui/material/FormControlLabel'

type DrawerOptions = {
  selectedGeneTypes: string[]
  selectedEvidenceTypes: string[]
  selectedGenesSwitchChecked: boolean
  selectedGeneRegionsSwitchChecked: boolean
  selectedGeneRegionsUpstreamSwitchChecked: boolean
  selectedGeneRegionsDownstreamSwitchChecked: boolean
  selectedHierarchySize: number
}

type GeneProcessDrawerProps = {
  drawerOpen: boolean
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void
  geneTypes: GeneType[]
  evidenceTypes: EvidenceType[]
  onSave: (data: DrawerOptions) => void
}

const GeneProcessDrawer: React.FC<GeneProcessDrawerProps> = ({
  drawerOpen,
  toggleDrawer,
  geneTypes,
  evidenceTypes,
  onSave,
}) => {
  const [selectedGeneTypes, setSelectedGeneTypes] = useState<string[]>([])
  const [tempSelectedGeneTypes, setTempSelectedGeneTypes] =
    useState<string[]>(selectedGeneTypes)
  const [selectedEvidenceTypes, setSelectedEvidenceTypes] = useState<string[]>(
    []
  )
  const [tempSelectedEvidenceTypes, setTempSelectedEvidenceTypes] = useState<
    string[]
  >(selectedEvidenceTypes)
  const [selectedGenesSwitchChecked, setSelectedGenesSwitchChecked] =
    useState(true)
  const [tempSelectedGenesSwitchChecked, setTempSelectedGenesSwitchChecked] =
    useState(selectedGenesSwitchChecked)
  const [
    selectedGeneRegionsSwitchChecked,
    setSelectedGeneRegionsSwitchChecked,
  ] = useState(true)
  const [
    tempSelectedGeneRegionsSwitchChecked,
    setTempSelectedGeneRegionsSwitchChecked,
  ] = useState(selectedGeneRegionsSwitchChecked)
  const [
    selectedGeneRegionsUpstreamSwitchChecked,
    setSelectedGeneRegionsUpstreamSwitchChecked,
  ] = useState(true)
  const [
    tempSelectedGeneRegionsUpstreamSwitchChecked,
    setTempSelectedGeneRegionsUpstreamSwitchChecked,
  ] = useState(selectedGeneRegionsUpstreamSwitchChecked)
  const [
    selectedGeneRegionsDownstreamSwitchChecked,
    setSelectedGeneRegionsDownstreamSwitchChecked,
  ] = useState(true)
  const [
    tempSelectedGeneRegionsDownstreamSwitchChecked,
    setTempSelectedGeneRegionsDownstreamSwitchChecked,
  ] = useState(selectedGeneRegionsDownstreamSwitchChecked)
  const [selectedHierarchySize, setSelectedHierarchySize] = useState<number>(12)
  const [tempHierarchySize, setTempHierarchySize] = useState<number>(
    selectedHierarchySize
  )

  const handleGeneTypesChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value
    const selectedValues = typeof value === 'string' ? value.split(',') : value
    setTempSelectedGeneTypes(selectedValues)
  }

  const handleEvidenceTypesChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value
    const selectedValues = typeof value === 'string' ? value.split(',') : value
    setTempSelectedEvidenceTypes(selectedValues)
  }

  const handleGenesSwitchChange = (isChecked: boolean) => {
    if (!isChecked && !tempSelectedGeneRegionsSwitchChecked) {
      setTempSelectedGeneRegionsSwitchChecked(true)
    }
    setTempSelectedGenesSwitchChecked(isChecked)
  }

  const handleGeneRegionsSwitchChange = (isChecked: boolean) => {
    if (!isChecked && !tempSelectedGenesSwitchChecked) {
      setTempSelectedGenesSwitchChecked(true)
    }
    setTempSelectedGeneRegionsSwitchChecked(isChecked)
  }

  const handleGeneRegionsUpstreamSwitchChange = (isChecked: boolean) => {
    if (!isChecked && !tempSelectedGeneRegionsDownstreamSwitchChecked) {
      setTempSelectedGeneRegionsDownstreamSwitchChecked(true)
    }
    setTempSelectedGeneRegionsUpstreamSwitchChecked(isChecked)
  }

  const handleGeneRegionsDownstreamSwitchChange = (isChecked: boolean) => {
    if (!isChecked && !tempSelectedGeneRegionsUpstreamSwitchChecked) {
      setTempSelectedGeneRegionsUpstreamSwitchChecked(true)
    }
    setTempSelectedGeneRegionsDownstreamSwitchChecked(isChecked)
  }

  const handleHierarchySizeChange = (
    event: Event,
    newValue: number | number[]
  ) => {
    setTempHierarchySize(newValue as number)
  }

  const handleSave = () => {
    if (
      tempSelectedGeneTypes.length > 0 &&
      tempSelectedEvidenceTypes.length > 0
    ) {
      setSelectedGeneTypes(tempSelectedGeneTypes)
      setSelectedEvidenceTypes(tempSelectedEvidenceTypes)
      setSelectedGenesSwitchChecked(tempSelectedGenesSwitchChecked)
      setSelectedGeneRegionsSwitchChecked(tempSelectedGeneRegionsSwitchChecked)
      setSelectedGeneRegionsUpstreamSwitchChecked(
        tempSelectedGeneRegionsUpstreamSwitchChecked
      )
      setSelectedGeneRegionsDownstreamSwitchChecked(
        tempSelectedGeneRegionsDownstreamSwitchChecked
      )
      setSelectedHierarchySize(tempHierarchySize)
      onSave({
        selectedGeneTypes: tempSelectedGeneTypes,
        selectedEvidenceTypes: tempSelectedEvidenceTypes,
        selectedGenesSwitchChecked: tempSelectedGenesSwitchChecked,
        selectedGeneRegionsSwitchChecked: tempSelectedGeneRegionsSwitchChecked,
        selectedGeneRegionsUpstreamSwitchChecked:
          tempSelectedGeneRegionsUpstreamSwitchChecked,
        selectedGeneRegionsDownstreamSwitchChecked:
          tempSelectedGeneRegionsDownstreamSwitchChecked,
        selectedHierarchySize: tempHierarchySize,
      })
    }
  }

  useEffect(() => {
    if (!drawerOpen) return

    const anyChange =
      selectedGeneTypes !== tempSelectedGeneTypes ||
      selectedEvidenceTypes !== tempSelectedEvidenceTypes ||
      selectedGenesSwitchChecked !== tempSelectedGenesSwitchChecked ||
      selectedGeneRegionsSwitchChecked !==
        tempSelectedGeneRegionsSwitchChecked ||
      selectedGeneRegionsUpstreamSwitchChecked !==
        tempSelectedGeneRegionsUpstreamSwitchChecked ||
      selectedGeneRegionsDownstreamSwitchChecked !==
        tempSelectedGeneRegionsDownstreamSwitchChecked

    if (!anyChange) return

    if (drawerOpen) {
      setTempSelectedGeneTypes(selectedGeneTypes)
      setTempSelectedEvidenceTypes(selectedEvidenceTypes)
      setTempSelectedGenesSwitchChecked(selectedGenesSwitchChecked)
      setTempSelectedGeneRegionsSwitchChecked(selectedGeneRegionsSwitchChecked)
      setTempSelectedGeneRegionsUpstreamSwitchChecked(
        selectedGeneRegionsUpstreamSwitchChecked
      )
      setTempSelectedGeneRegionsDownstreamSwitchChecked(
        selectedGeneRegionsDownstreamSwitchChecked
      )
      setTempHierarchySize(selectedHierarchySize)
    }

    if (selectedGeneTypes.length === 0) {
      setSelectedGeneTypes(geneTypes.map((geneType) => geneType.type))
    }

    if (selectedEvidenceTypes.length === 0) {
      setSelectedEvidenceTypes(
        evidenceTypes.map((evidenceType) => evidenceType.type)
      )
    }
  }, [
    drawerOpen,
    selectedGeneTypes,
    selectedEvidenceTypes,
    selectedGenesSwitchChecked,
    selectedGeneRegionsSwitchChecked,
    selectedGeneRegionsUpstreamSwitchChecked,
    selectedGeneRegionsDownstreamSwitchChecked,
  ])

  return (
    <Drawer
      anchor='right'
      open={drawerOpen}
      onClose={toggleDrawer(false)}
      sx={{
        width: 350,
        '& .MuiDrawer-paper': {
          width: 350,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
    >
      <Box
        role='presentation'
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <Typography variant='h6' sx={{ p: 2 }} align={'center'}>
          Gene Process Settings
        </Typography>
      </Box>
      <MultipleSelectCheckmarks
        options={geneTypes.map((geneType) => geneType.type)}
        selected={tempSelectedGeneTypes}
        handleChange={handleGeneTypesChange}
        label='Gene Types'
        defaultValue={selectedGeneTypes}
      />
      <MultipleSelectCheckmarks
        options={evidenceTypes.map((evidenceType) => evidenceType.type)}
        selected={tempSelectedEvidenceTypes}
        handleChange={handleEvidenceTypesChange}
        label='Evidence Code Categories'
        defaultValue={selectedEvidenceTypes}
      />
      <FormControl component='fieldset' sx={{ p: 1 }}>
        <FormLabel component='legend' sx={{ wordWrap: 'break-word', p: 2 }}>
          Use SNPs assigned to Gene or Gene Regions
        </FormLabel>
        <FormGroup
          aria-label='position'
          row
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <FormControlLabel
            value='genes'
            control={
              <Switch
                color='primary'
                checked={tempSelectedGenesSwitchChecked}
                onChange={(e) => handleGenesSwitchChange(e.target.checked)}
              />
            }
            label='Genes'
            labelPlacement='top'
          />
          <FormControlLabel
            value='geneRegions'
            control={
              <Switch
                color='primary'
                checked={tempSelectedGeneRegionsSwitchChecked}
                onChange={(e) =>
                  handleGeneRegionsSwitchChange(e.target.checked)
                }
              />
            }
            label='Gene Regions'
            labelPlacement='top'
          />
        </FormGroup>
      </FormControl>
      {tempSelectedGeneRegionsSwitchChecked && (
        <FormControl component='fieldset' sx={{ p: 1 }}>
          <FormLabel component='legend' sx={{ wordWrap: 'break-word', p: 2 }}>
            Use Downstream, Upstream or both from Gene Regions
          </FormLabel>
          <FormGroup
            aria-label='additional-options'
            row
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <FormControlLabel
              value='upstream'
              control={
                <Switch
                  color='primary'
                  checked={tempSelectedGeneRegionsUpstreamSwitchChecked}
                  onChange={(e) =>
                    handleGeneRegionsUpstreamSwitchChange(e.target.checked)
                  }
                />
              }
              label='Upstream'
              labelPlacement='top'
            />
            <FormControlLabel
              value='downstream'
              control={
                <Switch
                  color='primary'
                  checked={tempSelectedGeneRegionsDownstreamSwitchChecked}
                  onChange={(e) =>
                    handleGeneRegionsDownstreamSwitchChange(e.target.checked)
                  }
                />
              }
              label='Downstream'
              labelPlacement='top'
            />
          </FormGroup>
        </FormControl>
      )}
      <FormControl component='fieldset' sx={{ p: 1 }}>
        <FormLabel
          component='legend'
          sx={{ mb: 2, p: 2, wordWrap: 'break-word' }}
        >
          Size of the Gene Process hierarchy (Min 1, Max 12)
        </FormLabel>
        <Slider
          value={tempHierarchySize}
          onChange={handleHierarchySizeChange}
          aria-labelledby='hierarchy-size-slider'
          valueLabelDisplay='auto'
          step={1}
          sx={{ width: '95%', alignContent: 'center' }}
          marks
          min={1}
          max={12}
        />
      </FormControl>
      <Box sx={{ mt: 'auto', width: '80%', p: 2 }}>
        <Button
          variant='outlined'
          fullWidth
          disabled={
            tempSelectedGeneTypes.length === 0 ||
            tempSelectedEvidenceTypes.length === 0
          }
          onClick={handleSave}
          sx={{
            color: 'black',
            borderColor: 'black',
            '&:hover': {
              borderColor: 'black',
            },
          }}
        >
          Save
        </Button>
        {(tempSelectedGeneTypes.length === 0 ||
          tempSelectedEvidenceTypes.length === 0) && (
          <Typography
            variant='body2'
            color='error'
            align='center'
            sx={{ mt: 1 }}
          >
            At least one option in Gene Types and Evidence Code Categories must
            be selected.
          </Typography>
        )}
      </Box>
    </Drawer>
  )
}

export default GeneProcessDrawer
