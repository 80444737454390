import { Button, Stack } from '@mui/material'

type Props = {
  currentPageAllRowsSelected: boolean
  selectAll: boolean
  selectedRowIds: Set<number>
  allSampleCount: number
  handleAllSelection: () => void
  handleClearAllSelection: () => void
}

const SelectAllRowsPrompt: React.FC<Props> = ({
  currentPageAllRowsSelected,
  selectAll,
  selectedRowIds,
  allSampleCount,
  handleAllSelection,
  handleClearAllSelection,
}) => {
  return (
    <div style={{ padding: '8px 16px', backgroundColor: '#f1f1f1' }}>
      {currentPageAllRowsSelected && !selectAll && (
        <Stack className='' direction={'row'}>
          <p>
            All {Array.from(selectedRowIds).length} samples on this page are
            selected.
          </p>
          <Button onClick={handleAllSelection}>
            Select all {allSampleCount} samples in page.
          </Button>
        </Stack>
      )}
      {selectAll && (
        <Stack direction={'row'}>
          <p className=''>All {allSampleCount} samples samples are selected.</p>
          <Button onClick={handleClearAllSelection}>Clear selection</Button>
        </Stack>
      )}
    </div>
  )
}

export default SelectAllRowsPrompt
