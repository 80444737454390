import React, { useState } from 'react'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  SelectChangeEvent,
} from '@mui/material'

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
}

type MultipleSelectCheckmarksProps = {
  options: string[]
  selected: string[]
  defaultValue: string[]
  label: string
  handleChange: (event: SelectChangeEvent<string[]>) => void
}

const MultipleSelectCheckmarks: React.FC<MultipleSelectCheckmarksProps> = ({
  options,
  selected,
  defaultValue,
  handleChange,
  label,
}) => {
  const [selectedValues, setSelectedValues] = useState<string[]>(
    selected.length ? selected : defaultValue
  )

  const handleSelectionChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value as string[]
    if (value.includes('all-options')) {
      const allSelected = selectedValues.length === options.length
      const newValue = allSelected ? [] : options
      setSelectedValues(newValue)
      handleChange({
        target: { value: newValue, name: 'multiple-checkbox' },
        type: 'change',
      } as SelectChangeEvent<string[]>)
    } else {
      setSelectedValues(value)
      handleChange({
        target: { value: value, name: 'multiple-checkbox' },
        type: 'change',
      } as SelectChangeEvent<string[]>)
    }
  }

  return (
    <FormControl sx={{ m: 1, width: 300, p: 1 }}>
      <InputLabel id='multiple-checkbox-label'>{label}</InputLabel>
      <Select
        labelId='multiple-checkbox-label'
        id='multiple-checkbox'
        multiple
        value={selectedValues}
        onChange={handleSelectionChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) =>
          selected.length > 0 ? selected.join(', ') : 'None selected'
        }
        MenuProps={MenuProps}
      >
        <MenuItem value='all-options'>
          <Checkbox
            checked={selectedValues.length === options.length}
            indeterminate={
              selectedValues.length > 0 &&
              selectedValues.length < options.length
            }
          />
          <ListItemText primary='Select All' />
        </MenuItem>
        {options.map((item) => (
          <MenuItem key={item} value={item}>
            <Checkbox checked={selectedValues.includes(item)} />
            <ListItemText primary={item} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default MultipleSelectCheckmarks
