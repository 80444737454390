import React, { useState } from 'react'
import {
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Tooltip,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { useRemovePatientsFromClass } from '../hooks'
import { RemovePatientsFromClassRequest, TargetData } from '../types'

// const binButtonRedColor = '#ff1744'; // Replace with your desired red color.

interface DeleteClassDialogProps {
  targetManagerId: number
  className: string
  selectedSamplesCount: number
  selectedPatients: number[]
  selectAllPatients: boolean
  targetData: TargetData
}

const DeleteClassDialog: React.FC<DeleteClassDialogProps> = ({
  targetManagerId,
  className,
  selectedSamplesCount,
  selectedPatients,
  selectAllPatients,
  targetData,
}) => {
  const [dialogState, setDialogState] = useState(false)
  const removePatientsFromClassMutation =
    useRemovePatientsFromClass(targetManagerId)

  const toggleDialogState = () => {
    setDialogState((prevState) => !prevState)
  }

  const handleOpen = () => {
    toggleDialogState()
  }

  const handleClose = () => {
    toggleDialogState()
  }

  const handleConfirm = () => {
    const data: RemovePatientsFromClassRequest = {
      class_name: className,
      patient_id_list: selectedPatients,
      select_all: selectAllPatients,
    }
    if (targetData.filters.sample_name) {
      data.filters = data.filters ?? {}
      data.filters.sample_name = targetData.filters.sample_name
    }
    if (targetData.filters.group_name) {
      data.filters = data.filters ?? {}
      data.filters.group_name = targetData.filters.group_name
    }
    removePatientsFromClassMutation.mutate(data)
    handleClose()
  }

  return (
    <>
      <Tooltip
        title={
          selectedSamplesCount <= 0
            ? 'You need to select at least one patient sample to delete.'
            : 'Delete the selected sample.'
        }
      >
        <span>
          <IconButton
            disabled={selectedSamplesCount <= 0}
            aria-label='delete class'
            size='small'
            onClick={handleOpen}
          >
            <DeleteIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Dialog open={dialogState} onClose={handleClose}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete all selected samples on current page
            ?
          </DialogContentText>
          <DialogContentText>
            Selected Samples: <strong>{selectedSamplesCount}</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleConfirm} color='error' variant='contained'>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DeleteClassDialog
