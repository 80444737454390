import {
  PatientAndClassesQueryParams,
  SAMPLES_TYPE,
} from './components/SampleClassfier/types'

export const targetDataManagerKeys = {
  all: ['target-data-manager'] as const,
  targetDataManager: (id: number) =>
    [...targetDataManagerKeys.all, id] as const,
  disease: (diseaseId: number) =>
    [...targetDataManagerKeys.all, 'disease', diseaseId] as const,
  patientAndClasses: (id: number) =>
    ['assign-patients-and-classes', id] as const,
  // new
  patientClasses: (targetManagerId: number) => [
    ...targetDataManagerKeys.targetDataManager(targetManagerId),
    'class-names',
  ],
  patientsBase: (targetManagerId: number) => [
    ...targetDataManagerKeys.targetDataManager(targetManagerId),
    'patients',
  ],
  patientsWithClassId: (targetManagerId: number, classId: number) => [
    ...targetDataManagerKeys.patientsBase(targetManagerId),
    classId,
  ],
  patientsWithSampleType: (
    targetManagerId: number,
    classId: number,
    sampleType: SAMPLES_TYPE
  ) => [
    ...targetDataManagerKeys.patientsWithClassId(targetManagerId, classId),
    sampleType,
  ],
  patients: (
    targetManagerId: number,
    classId: number,
    sampleType: SAMPLES_TYPE,
    parameters: PatientAndClassesQueryParams
  ) => [
    ...targetDataManagerKeys.patientsWithSampleType(
      targetManagerId,
      classId,
      sampleType
    ),
    parameters,
  ],
}
