import React, { useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography,
  IconButton,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { useRemovePatientClass } from '../hooks'
import { SAMPLES_TYPE } from '../types'

interface PatientClassProps {
  class_name: string
  targetDataManagerId: number
  handleTabSelect: (selectedClassId: number, sampleType: SAMPLES_TYPE) => void
}

const PatientClassItem: React.FC<PatientClassProps> = ({
  class_name,
  targetDataManagerId,
  handleTabSelect,
}) => {
  const [open, setOpen] = useState(false)
  const removePatientClassMutation = useRemovePatientClass(targetDataManagerId)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirmRemove = (event: React.MouseEvent) => {
    event.stopPropagation()
    removePatientClassMutation.mutate({
      class_name: class_name,
    })
    setOpen(false)
    handleTabSelect(0, SAMPLES_TYPE.SAMPLES_TYPE_UNCLASSIFIED)
  }
  return (
    <>
      <Typography variant='body2'>{class_name}</Typography>
      <IconButton
        aria-label='delete class'
        size='small'
        onClick={handleClickOpen}
      >
        <DeleteIcon />
      </IconButton>

      {/* Remove Samples Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Remove Patient Class</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove the class "
            <strong>{class_name}</strong>" ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleConfirmRemove} color='error' autoFocus>
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default PatientClassItem
