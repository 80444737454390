import { useParams } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import PrsResults from '../components/prsResults/PrsResults'
import { Box, CircularProgress, Grid } from '@mui/material'
import { useIsMutating, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useAnalysis } from './AnalysisManager/hooks'
import { usePatientsAndClasses } from './TargetDataManager/hooks'
import { baseDataManagerKeys } from './BaseDatamanager/queries'

type Props = {
  analysisId: string
}

const GeneralAnalysis: React.FC = () => {
  const analysisId = Number(useParams<Props>().analysisId)

  const { backendRequest, logout } = useAuth()

  const analysisManager = useAnalysis(analysisId)

  if (analysisManager.isError) {
    if (axios.isAxiosError(analysisManager.error)) {
      const status = analysisManager.error?.response?.status
      if (status === 401) {
        logout()
      }
    }
  }

  const responseAnalysisBaseDataManager =
    analysisManager.data?.base_data_manager
  const baseDataManagerId = !responseAnalysisBaseDataManager?.id
    ? -1
    : responseAnalysisBaseDataManager.id

  const { data: responseSnps, isLoading: snpsLoading } = useQuery({
    queryKey: baseDataManagerKeys.snps(baseDataManagerId),
    queryFn: async () => {
      const responseSnps = await backendRequest({
        method: 'GET',
        endpoint: `/api/base-data-manager/${responseAnalysisBaseDataManager?.id}/snps`,
        requiresAuth: true,
      })
      return responseSnps.data
    },
    enabled: !!responseAnalysisBaseDataManager?.id,
    staleTime: 300_000,
  })

  const patientsAndClasses = usePatientsAndClasses(
    analysisManager.data?.target_data_manager.id ?? -1,
    responseSnps != undefined
  )

  const isMutating = useIsMutating()
  const isLoading = [
    analysisManager.isLoading,
    snpsLoading,
    patientsAndClasses.isLoading,
    isMutating > 0,
  ].some((loading) => loading)

  if (isLoading) {
    return (
      <Grid
        container
        spacing={2}
        pl={15}
        pr={8}
        mt={-4}
        justifyContent='center'
        alignItems='center'
        style={{ minHeight: '100vh' }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      </Grid>
    )
  }

  if (patientsAndClasses.isSuccess) {
    return (
      <Grid container spacing={2} pl={15} pr={8} mt={-4}>
        <PrsResults
          pageHeading='General Analysis'
          baseDataName={analysisManager.data?.base_data_manager.name || ''}
          targetDataName={analysisManager.data?.target_data_manager.name || ''}
          snpIds={responseSnps}
          targetData={patientsAndClasses.data}
          isLoading={isLoading}
          analysisId={analysisId}
          baseDataId={baseDataManagerId}
        />
      </Grid>
    )
  }
  return <></>
}

export default GeneralAnalysis
