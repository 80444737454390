import React, { useEffect, useMemo, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
  Tabs,
  Tab,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import { removeAnalysisKeysFromCache, useManagePatients } from '../hooks'
import { useQueryClient } from '@tanstack/react-query'
import { enqueueSnackbar } from 'notistack'
import { ManagePatientsRequest, SAMPLES_TYPE, TargetData } from '../types'
import { targetDataManagerKeys } from '../../../../TargetDataManager/queries'
import LoadingButton from '@mui/lab/LoadingButton'
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove'
import { PatientClass } from '../../../../TargetDataManager/types'

interface ManagePatientsDialogProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  targetDataManagerId: number
  patientClasses: PatientClass[]
  selectAll: boolean
  selectedIds: number[]
  sourceClassName?: string
  targetData: TargetData
  selectedSamplesCount: number
  currentSampleType: SAMPLES_TYPE
  handleClassifiedSamplesTabSelect: (className: string) => void
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

export const ManagePatientsDialog = React.forwardRef<
  HTMLDivElement,
  ManagePatientsDialogProps
>(
  (
    {
      open,
      setOpen,
      targetDataManagerId,
      patientClasses,
      selectAll,
      selectedIds,
      sourceClassName,
      targetData,
      selectedSamplesCount,
      currentSampleType,
      handleClassifiedSamplesTabSelect,
    },
    ref
  ) => {
    // TODO: reset filter state after submit
    const [newClassName, setNewClassName] = useState<string>('')
    const managePatients = useManagePatients(targetDataManagerId)
    const [value, setValue] = React.useState(0)

    const queryClient = useQueryClient()

    const filteredPatientClasses = patientClasses.filter((patientClass) => {
      return patientClass.class_name !== sourceClassName
    })

    const isSubmitButtonDisabled =
      newClassName.length == 0 || managePatients.isPending

    const handleClassNameChange = (
      event:
        | SelectChangeEvent<string>
        | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setNewClassName(event.target.value.trim())
    }

    const toggleDialogState = () => {
      setOpen((prevState) => !prevState)
      setNewClassName('')
    }

    const handleSubmitAll = () => {
      if (value === 0) {
        handleSubmitClass(false)
      } else if (value === 1) {
        handleSubmitClass(true)
      }
    }

    const handletabChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue)
      // reset class name
      setNewClassName('')
    }

    const handleSubmitClass = async (createNewClass: boolean) => {
      let targetClassname = newClassName

      const request_params: ManagePatientsRequest = {
        target_class_name: newClassName,
        select_all: selectAll,
      }

      if (sourceClassName) {
        request_params.source_class_name = sourceClassName
      }

      if (!selectAll) {
        request_params.patient_ids = selectedIds
      } else {
        request_params.filters = targetData.filters
      }

      if (createNewClass) {
        request_params.create_new_class = true
        request_params.target_class_name = newClassName
        targetClassname = request_params.target_class_name

        if (newClassName.length === 0) {
          enqueueSnackbar('New class name cannot be empty.', {
            variant: 'warning',
          })
          return
        }
      }

      managePatients.mutate(request_params, {
        onSuccess: async () => {
          handleClassifiedSamplesTabSelect(targetClassname)
          toggleDialogState()
          await queryClient.refetchQueries({
            queryKey: targetDataManagerKeys.patientClasses(targetDataManagerId),
          })
          queryClient.refetchQueries({
            queryKey: targetDataManagerKeys.patientsWithClassId(
              targetDataManagerId,
              SAMPLES_TYPE.SAMPLES_TYPE_UNCLASSIFIED
            ),
          })

          removeAnalysisKeysFromCache(
            queryClient,
            targetDataManagerId,
            'analysis'
          )
        },
      })
    }

    const addToExistingClassIsDisabled = useMemo(() => {
      return (
        patientClasses.length === 0 ||
        (filteredPatientClasses.length === 0 &&
          currentSampleType === SAMPLES_TYPE.SAMPLES_TYPE_CLASSIFIED)
      )
    }, [patientClasses, filteredPatientClasses, currentSampleType])

    useEffect(() => {
      if (addToExistingClassIsDisabled) {
        setValue(1)
      } else {
        setValue(0)
      }
    }, [addToExistingClassIsDisabled])

    return (
      <Dialog
        ref={ref}
        open={open}
        onClose={() => {
          toggleDialogState()
        }}
        fullWidth
      >
        <DialogTitle>Add selected samples to patient classes</DialogTitle>
        <DialogContent>
          <p>
            Samples selected: <strong>{selectedSamplesCount}</strong>.
          </p>

          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handletabChange}
              aria-label='basic tabs example'
              centered
              variant='fullWidth'
            >
              <Tab
                disabled={addToExistingClassIsDisabled}
                label='Add To Existing Class'
                {...a11yProps(0)}
              />
              <Tab label='Add To New Class' {...a11yProps(1)} />
            </Tabs>
          </Box>
          {/* Add to existing class option */}
          <CustomTabPanel value={value} index={0}>
            <FormControl fullWidth>
              <InputLabel id='patient-class-select-label'>
                Select target class
              </InputLabel>
              <Select
                labelId='patient-class-select-label'
                value={newClassName}
                onChange={handleClassNameChange}
                label='Select target class'
                sx={{ height: '56px' }}
              >
                {filteredPatientClasses.map((patientClass) => (
                  <MenuItem
                    key={patientClass.id}
                    value={patientClass.class_name}
                  >
                    {patientClass.class_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </CustomTabPanel>
          {/* Create a new class option */}
          <CustomTabPanel value={value} index={1}>
            <TextField
              id='outlined-basic'
              fullWidth={true}
              label='New class name'
              variant='outlined'
              value={newClassName}
              onChange={handleClassNameChange}
            />
          </CustomTabPanel>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={toggleDialogState}
            disabled={managePatients.isPending}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={managePatients.isPending}
            onClick={handleSubmitAll}
            color='primary'
            variant='outlined'
            disabled={isSubmitButtonDisabled}
            loadingPosition='end'
            endIcon={<DriveFileMoveIcon />}
          >
            Move Samples
          </LoadingButton>
        </DialogActions>
      </Dialog>
    )
  }
)
