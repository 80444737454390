import * as React from 'react'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import ViolinPlot, { ViolinPlotData } from './ViolinPlot'
import { HighlightedSampleData } from '../prsResults/types'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

type ViolinPlotDialogProps = {
  open: boolean
  handleClose: () => void
  data: ViolinPlotData[]
  isLoading: boolean
  highlightedSampleData?: HighlightedSampleData
  controlClass: string
}

export default function ViolinPlotDialog({
  open,
  handleClose,
  data,
  isLoading,
  highlightedSampleData,
  controlClass,
}: ViolinPlotDialogProps) {
  return (
    <React.Fragment>
      <BootstrapDialog
        fullScreen
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id='customized-dialog-title'>
          Violin Plot
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <ViolinPlot
            dialogMode={true}
            data={data}
            isLoading={isLoading}
            controlClass={controlClass}
            highlightedSampleData={highlightedSampleData}
          />
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  )
}
