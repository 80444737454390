import { useQuery } from '@tanstack/react-query'
import { useAuth } from '../../context/AuthContext'
import { prsCalculationKeys } from './queries'
import {
  FilteredPatientClassesFunction,
  PrsCalculationsResponse,
  RelevantVariants,
} from './types'

export const useUnweightedPrsCalculations = (
  baseDataId: number,
  targetDataId: number,
  relevantUnweightedSnps: RelevantVariants | undefined,
  filteredPatientClasses: FilteredPatientClassesFunction
) => {
  const { backendRequest } = useAuth()

  return useQuery<PrsCalculationsResponse>({
    queryKey: prsCalculationKeys.unweightedCalculations(
      baseDataId,
      targetDataId
    ),
    queryFn: async () => {
      const responseUnweightedPrsCalculations = await backendRequest({
        method: 'POST',
        endpoint: '/api/prs/unweighted-prs-calculation',
        requiresAuth: true,
        body: {
          gwas_variants_ids:
            relevantUnweightedSnps?.deduplicated_gwas_variants_ids,
          patient_classes: filteredPatientClasses(),
        },
      })
      return responseUnweightedPrsCalculations.data
    },
    enabled:
      !!relevantUnweightedSnps?.deduplicated_gwas_variants_ids &&
      relevantUnweightedSnps.deduplicated_gwas_variants_ids.length > 0 &&
      !!baseDataId &&
      !!targetDataId &&
      filteredPatientClasses().length > 0,
    staleTime: 300_000,
  })
}

export const useWeightedPrsResults = (
  baseDataId: number,
  targetDataId: number,
  relevantWeightedSnps: RelevantVariants | undefined,
  filteredPatientClasses: FilteredPatientClassesFunction
) => {
  const { backendRequest } = useAuth()

  return useQuery<PrsCalculationsResponse>({
    queryKey: prsCalculationKeys.weightedCalculations(baseDataId, targetDataId),
    queryFn: async () => {
      const responseWeightedPrsCalculations = await backendRequest({
        method: 'POST',
        endpoint: '/api/prs/weighted-prs-calculations',
        requiresAuth: true,
        body: {
          gwas_variants_ids:
            relevantWeightedSnps?.deduplicated_gwas_variants_ids,
          patient_classes: filteredPatientClasses(),
        },
      })
      return responseWeightedPrsCalculations.data
    },
    enabled:
      !!relevantWeightedSnps?.deduplicated_gwas_variants_ids &&
      relevantWeightedSnps.deduplicated_gwas_variants_ids.length > 0 &&
      !!baseDataId &&
      !!targetDataId &&
      filteredPatientClasses().length > 0,
    staleTime: 300_000,
  })
}
