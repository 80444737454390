import React, { useEffect, useState } from 'react'

import { Box, Skeleton } from '@mui/material'
import Plot from 'react-plotly.js'

export type ROCPlotData = {
  groupName: string
  y: number[]
}

type Props = {
  data: ROCPlotData[]
  isLoading: boolean
  controlClass: string
  experimentalClass: string
}

const ROCPlot: React.FC<Props> = (props) => {
  const [fpr, setFpr] = useState<number[]>([])
  const [tpr, setTpr] = useState<number[]>([])

  useEffect(() => {
    const controlScore = props.data.find(
      (group) => group.groupName === props.controlClass
    ) || { y: [] }
    const experimentalScore = props.data.find(
      (group) => group.groupName === props.experimentalClass
    ) || { y: [] }

    const sortedControlScores = controlScore.y.slice().sort((a, b) => b - a)
    const sortedExperimentalScores = experimentalScore.y
      .slice()
      .sort((a, b) => b - a)

    const fprTemp: number[] = [0]
    const tprTemp: number[] = [0]

    sortedExperimentalScores.forEach((prsValue) => {
      const countExperimental = sortedExperimentalScores.filter(
        (prsNum) => prsNum >= prsValue
      ).length
      const countControl = sortedControlScores.filter(
        (prsNum) => prsNum >= prsValue
      ).length

      fprTemp.push(
        sortedControlScores.length
          ? countControl / sortedControlScores.length
          : countControl
      )
      tprTemp.push(
        sortedExperimentalScores.length
          ? countExperimental / sortedExperimentalScores.length
          : countExperimental
      )
    })

    fprTemp.push(1)
    tprTemp.push(1)

    setFpr(fprTemp)
    setTpr(tprTemp)
  }, [props.data, props.controlClass, props.experimentalClass])

  return !props.isLoading ? (
    <Plot
      data={[
        {
          x: fpr,
          y: tpr,
          type: 'scatter',
          mode: 'lines+markers',
          fill: 'tozeroy',
          name: 'ROC',
          line: { shape: 'hv' },
        },
        {
          x: [0, 1],
          y: [0, 1],
          type: 'scatter',
          mode: 'lines',
          line: { dash: 'dash' },
        },
      ]}
      layout={{
        paper_bgcolor: 'transparent',
        plot_bgcolor: 'transparent',
        xaxis: { title: 'False Positive Rate' },
        yaxis: { title: 'True Positive Rate' },
        showlegend: false,
      }}
      style={{ width: '100%', height: '100%' }}
    />
  ) : (
    <Box width='100%' height='100%' p={3}>
      <Skeleton variant='rounded' height='90%' />
    </Box>
  )
}

export default ROCPlot
