import React, { useEffect, useState } from 'react'

import { useAuth } from '../context/AuthContext'
import { enqueueSnackbar } from 'notistack'
import { Box, Grid, Stack, Typography } from '@mui/material'
import PageHeading from '../components/PageHeading'
import { iconsObj } from '../icons/Icons'
import { ListItemWithAvatar } from '../components/buttons/TargetAndBaseDataButton'
import { useParams } from 'react-router-dom'
import PrsResultsDialog from '../components/dialogs/PrsResultsDialog'
import PathwayBasedPrsSettingsDialog from '../components/dialogs/PathwayBasedPrsSettingsDialog'
import { Option } from '../components/forms/FormProps'
import NetworkPlot, {
  NetworkPlotData,
  Node,
} from '../components/plots/NetworkPlot'
import { Analysis } from './AnalysisManager/types'
import { TargetDataResponse } from './TargetDataManager/types'
import { useQueryClient } from '@tanstack/react-query'

type LinkResponse = {
  source_id: number
  target_id: number
  label: string
}

type ExtendedNode = Node & {
  st_id: string
}

type PathwaysResponseData = {
  nodes: ExtendedNode[]
  links: LinkResponse[]
}

type Props = {
  analysisId: string
}

export type GeneType = {
  id: number
  type: string
}

const PathwayBasedAnalysis: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const queryClient = useQueryClient()

  const clearAnalysisCache = () => {
    const queries = queryClient.getQueryCache().findAll()

    const matchingKeys = queries
      .filter(({ queryKey }) => {
        if (queryKey[0] !== 'analysis') {
          return false
        }
        const baseManagerKey = (queryKey[1] as { baseDataId?: number })
          .baseDataId
        const targetManagerKey = (queryKey[1] as { targetDataId?: number })
          .targetDataId

        if (
          baseManagerKey === analysis.base_data_manager.id &&
          targetManagerKey === analysis.target_data_manager.id
        ) {
          return true
        }
      })
      .map(({ queryKey }) => queryKey)
    console.log('Matching keys', matchingKeys)

    matchingKeys.forEach((key) => {
      queryClient.removeQueries({ queryKey: key })
    })
  }
  const [snpsLoading, setSnpsLoading] = useState<boolean>(false)

  const [analysis, setAnalysis] = useState<Analysis>({} as Analysis)
  const [snpIds, setSnpIds] = useState<number[]>([])
  const [pathwaySnpIds, setPathwaySnpIds] = useState<number[]>([])
  const [selectedPathwayName, setSelectedPathwayName] = useState<string>('')
  const [selectedPathwayStId, setSelectedPathwayStId] = useState<string>('')
  const [targetData, setTargetData] = useState<TargetDataResponse>(
    {} as TargetDataResponse
  )
  const [pathwaysGraphData, setPathwaysGraphData] = useState<
    NetworkPlotData | undefined
  >(undefined)
  const [geneTypes, setGeneTypes] = useState<GeneType[]>([])

  const [prsSettingsDialogOpen, setPrsSettingsDialogOpen] =
    useState<boolean>(false)
  const [showPRSDialogDialog, setShowPRSDialogDialog] = useState<boolean>(false)

  const { backendRequest, logout } = useAuth()

  const analysisId = Number(useParams<Props>().analysisId)

  useEffect(() => {
    setIsLoading(true)

    const getData = async () => {
      const responseAnalysis = await backendRequest({
        method: 'GET',
        endpoint: `/api/analyses-manager/${analysisId}`,
        requiresAuth: true,
      })

      const analysisData = responseAnalysis.data as Analysis | undefined

      const responseSnps = await backendRequest({
        method: 'GET',
        endpoint: `/api/base-data-manager/${analysisData?.base_data_manager.id}/snps`,
        requiresAuth: true,
      })

      const snpIds = responseSnps.data as number[] | undefined

      const responsePathwaysStableIds = await backendRequest({
        method: 'POST',
        endpoint: '/api/reactome-pathways/get-pathways-for-variants',
        requiresAuth: true,
        body: snpIds,
      })

      const pathwaysStableIds = responsePathwaysStableIds.data as
        | string[]
        | undefined

      const responsePathwaysGraphData = await backendRequest({
        method: 'POST',
        endpoint: '/api/reactome-pathways/',
        requiresAuth: true,
        body: pathwaysStableIds,
      })

      const pathwaysGraphData =
        responsePathwaysGraphData.data as PathwaysResponseData

      const responseTargetData = await backendRequest({
        method: 'GET',
        endpoint: `/api/target-data-manager/assign-patients-and-classes/${analysisData?.target_data_manager.id}`,
        requiresAuth: true,
      })

      const targetData = responseTargetData.data as
        | TargetDataResponse
        | undefined

      const responseGeneTypes = await backendRequest({
        method: 'GET',
        endpoint: '/api/genes/types',
        requiresAuth: true,
      })

      const geneTypes = responseGeneTypes.data as GeneType[]

      return {
        analysisData: analysisData,
        analysisStatus: responseAnalysis.status,
        snpIds: snpIds,
        snpsStatus: responseSnps.status,
        pathwaysGraphDataStatus: responsePathwaysGraphData.status,
        pathwaysGraphData: pathwaysGraphData,
        targetDataStatus: responseTargetData.status,
        targetData: targetData,
        geneTypesStatus: responseGeneTypes.status,
        geneTypes: geneTypes,
      }
    }
    getData()
      .then(
        ({
          analysisData,
          analysisStatus,
          pathwaysGraphDataStatus,
          pathwaysGraphData,
          snpIds,
          snpsStatus,
          targetDataStatus,
          targetData,
          geneTypesStatus,
          geneTypes,
        }) => {
          if (analysisStatus === 200 && analysisData) {
            setAnalysis(analysisData)
          } else if (analysisStatus === 401) {
            logout()
          }
          if (pathwaysGraphDataStatus === 200 && pathwaysGraphData) {
            const pathwaysLinks = pathwaysGraphData.links.map((link) => {
              return {
                source: link.source_id,
                target: link.target_id,
                label: link.label,
              }
            })
            setPathwaysGraphData({
              nodes: pathwaysGraphData.nodes,
              links: pathwaysLinks,
            })
          } else if (pathwaysGraphDataStatus === 401) {
            logout()
          }
          if (snpsStatus === 200 && snpIds) {
            setSnpIds(snpIds)
          }
          if (targetDataStatus === 200 && targetData) {
            setTargetData(targetData)
          }
          if (geneTypesStatus === 200 && geneTypes) {
            setGeneTypes(geneTypes)
          }
        }
      )
      .catch((error) => {
        enqueueSnackbar(`An error occurred. ${error.response?.data.detail}`, {
          variant: 'error',
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const getSnpsForPathway = async (
    includeGeneSnps: boolean,
    includeGeneRegionSnps: boolean,
    excludedGeneTypes: Option[]
  ) => {
    clearAnalysisCache()
    setSnpsLoading(true)
    const response = await backendRequest({
      method: 'POST',
      endpoint: '/api/reactome-pathways/get-variants-for-pathway',
      requiresAuth: true,
      params: {
        include_gene_snps: includeGeneSnps,
        include_gene_region_snps: includeGeneRegionSnps,
        selected_reactome_pathway_st_id: selectedPathwayStId,
      },
      body: {
        gwas_variants_ids: snpIds,
        excluded_gene_types_ids: excludedGeneTypes.map(
          (geneType) => geneType.id
        ),
      },
    })
    setSnpsLoading(false)

    if (response.status === 200) {
      setPathwaySnpIds(response.data as number[])
    } else if (response.status === 401) {
      logout()
    } else {
      enqueueSnackbar(`An error occurred. ${response.data.detail}`, {
        variant: 'error',
      })
    }
  }

  return (
    <React.Fragment>
      <Grid
        container
        direction='column'
        justifyContent='space-between'
        alignItems='stretch'
        height={1}
        pl={15}
        pr={8}
        mt={-4}
        overflow='hidden'
      >
        <Grid
          container
          item
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item md={6} xs={8}>
            <Box display='flex' alignItems='center' gap={2}>
              <PageHeading icon={iconsObj.ANALYSES}>
                Pathway Based Analysis
              </PageHeading>
              <Typography variant='h6' id='analysis-name' textAlign='left'>
                {analysis?.name}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} md={6} lg={6} xl={4}>
            {/* TODO: Add experiment selection */}
          </Grid>
        </Grid>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          item
          height={1}
        >
          <Grid item xs={12} sm={12} md={6} lg={5} xl={3}>
            <Stack direction='row'>
              <ListItemWithAvatar
                icon={iconsObj.TARGET_DATA}
                primaryLabel='Target Data'
                secondaryLabel={analysis.target_data_manager?.name}
              />
              <ListItemWithAvatar
                icon={iconsObj.BASE_DATA}
                primaryLabel='Base Data'
                secondaryLabel={analysis.base_data_manager?.name}
              />
            </Stack>
          </Grid>
          <Grid item xs={4} md={6} lg={6} xl={4}>
            {/* TODO: Add experiment selection */}
          </Grid>
        </Grid>
        <Grid
          container
          item
          direction='column'
          mt={3}
          justifyContent='space-between'
          alignItems='stretch'
          height={1}
          width={1}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant='h6' textAlign='left' ml={1}>
              Select Pathway
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <NetworkPlot
              isLoading={isLoading}
              data={pathwaysGraphData}
              onNodeClick={(node: Node) => {
                setPathwaySnpIds([])
                setSelectedPathwayStId(node.st_id)
                setSelectedPathwayName(node.label)
                setPrsSettingsDialogOpen(true)
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <PathwayBasedPrsSettingsDialog
        open={prsSettingsDialogOpen}
        setOpen={setPrsSettingsDialogOpen}
        geneTypes={geneTypes}
        setShowPRSDialogDialog={setShowPRSDialogDialog}
        handleSubmitClose={(
          includeGeneSnps: boolean,
          includeGeneRegionSnps: boolean,
          excludedGeneTypes: Option[]
        ) =>
          getSnpsForPathway(
            includeGeneSnps,
            includeGeneRegionSnps,
            excludedGeneTypes
          )
        }
      />
      <PrsResultsDialog
        show={showPRSDialogDialog}
        setShow={setShowPRSDialogDialog}
        pageHeading={`Pathway: ${selectedPathwayName}`}
        baseDataName={analysis.base_data_manager?.name || ''}
        targetDataName={analysis.target_data_manager?.name || ''}
        snpIds={pathwaySnpIds}
        targetData={targetData}
        isLoading={isLoading || snpsLoading}
        analysisId={analysisId}
        baseDataId={analysis.base_data_manager?.id}
      />
    </React.Fragment>
  )
}

export default PathwayBasedAnalysis
