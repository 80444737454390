import {
  keepPreviousData,
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
} from '@tanstack/react-query'
import { useAuth } from '../../../../context/AuthContext'
import {
  CreatePatientClassInput,
  CreatePatientClassResponse,
  FilterOptions,
  ManagePatientsRequest,
  PaginationOptions,
  Params,
  PatientAndClassesQueryParams,
  PatientClassWithPatientsResponse,
  PatientWithMetadataResponse,
  RemovePatientClassRequest,
  RemovePatientClassResponse,
  RemovePatientsFromClassRequest,
  RemovePatientsFromClassResponse,
  SampleGroupResponse,
  SAMPLES_TYPE,
  TargetDataManagerV2AddResponse,
  UpdateTargetDataManagerRequest,
  UpdateTargetDataManagerResponse,
  UseClassNamesOptions,
  UsePatientAndClassesPaginatedOptions,
} from './types'
import { AxiosError } from 'axios'
import { enqueueSnackbar } from 'notistack'
import { targetDataManagerKeys } from '../../../TargetDataManager/queries'
import {
  PatientClass,
  TargetOrBaseData,
} from '../../../TargetDataManager/types'
import { EfoDisease } from '../../../AnalysisManager/types'
import { useNavigate } from 'react-router-dom'
import { analysisManagerKeys } from '../../../AnalysisManager/queries'

/**
 * Helper function to get the appropriate endpoint based on SAMPLES_TYPE.
 */
function getEndpoint(targetDataId: number, sampleType: SAMPLES_TYPE): string {
  if (sampleType === SAMPLES_TYPE.SAMPLES_TYPE_UNCLASSIFIED) {
    return `/api/target-data-manager/classes-with-patients/${targetDataId}/classes/unclassified`
  }

  // For classified samples:
  return `/api/target-data-manager/classes-with-patients/${targetDataId}/classes/classified`
}

/**
 * Helper function to create request parameters (query params).
 */
function createRequestParams(pagination: PaginationOptions): Params {
  const baseParams: Params = {
    prev_id: pagination.prevId,
    last_id: pagination.lastId,
    page_size: pagination.pageSize,
  }

  return baseParams
}

/**
 * Helper function to create the request body.
 */
function createRequestBody(
  pagination: FilterOptions,
  sampleType: SAMPLES_TYPE,
  classId?: number
) {
  if (
    sampleType === SAMPLES_TYPE.SAMPLES_TYPE_CLASSIFIED &&
    classId !== undefined
  ) {
    return {
      sample_name: pagination.sample_name,
      group_name: pagination.group_name,
      metadata: pagination.metadata,
      class_id: classId,
    }
  }
  return {
    sample_name: pagination.sample_name,
    group_name: pagination.group_name,
    metadata: pagination.metadata,
  }
}

/**
 * Main hook to fetch paginated patient and class data.
 */
export const usePatientAndClassesPaginated = ({
  targetDataId,
  classId,
  options,
  sampleType = SAMPLES_TYPE.SAMPLES_TYPE_UNCLASSIFIED,
}: UsePatientAndClassesPaginatedOptions) => {
  const { backendRequest } = useAuth()

  const requestParams = createRequestParams(options.pagination)
  const requestBody = createRequestBody(options.filters, sampleType, classId)
  const endpoint = getEndpoint(targetDataId, sampleType)
  const params: PatientAndClassesQueryParams = {
    pagination: options.pagination,
    filters: options.filters,
  }
  const queryKey = targetDataManagerKeys.patients(
    targetDataId,
    classId,
    sampleType,
    params
  ) // getQueryKey(sampleType, tableOptions, pagination)
  console.log('Request paramters: ', options.filters)

  return useQuery<PatientClassWithPatientsResponse>({
    queryKey,
    queryFn: async () => {
      const response = await backendRequest({
        method: 'POST',
        endpoint,
        requiresAuth: true,
        params: requestParams,
        body: requestBody,
      })

      const data = response.data
      if (data === undefined) {
        throw new Error('Data unavailable')
      }
      return data
    },
    placeholderData: keepPreviousData,
    enabled: !!targetDataId,
    refetchOnWindowFocus: true,
  })
}

/**
 * A custom hook that fetches the list of class names using React Query.
 *
 * @param targetManagerId The ID for which to fetch the class names.
 * @returns An object with `data`, `isLoading`, `isError`, etc. from useQuery.
 */
export function useClassNames({ targetManagerId }: UseClassNamesOptions) {
  const { backendRequest } = useAuth()

  return useQuery<PatientClass[]>({
    queryKey: targetDataManagerKeys.patientClasses(targetManagerId),
    queryFn: async () => {
      const response = await backendRequest({
        method: 'GET',
        endpoint: `/api/target-data-manager/v2/assign-patients-and-classes/${targetManagerId}/names`,
        requiresAuth: true,
      })
      return response.data as PatientClass[]
    },
    enabled: !!targetManagerId, // Only run if targetManagerId is truthy
  })
}

/**
 * A hook to create a new patient class.
 *
 * @returns An object with `mutate`, `data`, `isLoading`, `isError`, etc.
 */
export function useCreatePatientClass(targetManagerId: number) {
  const { backendRequest } = useAuth()
  const queryClient = useQueryClient()

  return useMutation<
    CreatePatientClassResponse,
    AxiosError<{ detail: string }>,
    CreatePatientClassInput
  >({
    mutationFn: async (input: CreatePatientClassInput) => {
      const response = await backendRequest({
        method: 'POST',
        endpoint: `/api/target-data-manager/create-patient-class/${input.targetDataManagerId}`,
        requiresAuth: true,
        body: {
          class_name: input.className,
        },
      })
      return response.data as CreatePatientClassResponse
    },
    onSuccess: (newPatientClass, variables: CreatePatientClassInput) => {
      // Typ variables: CreatePatientClassInput
      if (!newPatientClass) return

      queryClient.setQueryData(
        targetDataManagerKeys.patientClasses(targetManagerId),
        (oldData: PatientClass[] | undefined) => {
          return [...(oldData ?? []), newPatientClass]
        }
      )
      enqueueSnackbar(
        `Patient class ${variables.className} was successfuly created.`,
        {
          variant: 'success',
          autoHideDuration: 3000,
        }
      )
    },
    onError: (error) => {
      enqueueSnackbar(error.response?.data.detail, {
        variant: 'error',
        autoHideDuration: 3000,
      })
    },
  })
}

export function useManagePatients(targetDataManagerId: number) {
  const { backendRequest } = useAuth()
  const queryClient = useQueryClient()

  return useMutation<
    TargetDataManagerV2AddResponse,
    AxiosError<{ detail: string }>,
    ManagePatientsRequest
  >({
    mutationFn: async (input: ManagePatientsRequest) => {
      const response = await backendRequest({
        method: 'POST',
        endpoint: `/api/target-data-manager/manage-patients/${targetDataManagerId}`,
        requiresAuth: true,
        body: {
          source_class_name: input.source_class_name,
          target_class_name: input.target_class_name,
          patient_ids: input.patient_ids, // Optional for specific patient selection
          select_all: input.select_all, // Support for "Select All"
          create_new_class: input.create_new_class,
          filters: input.filters, // Optional filters for dynamic query
        },
      })
      return response.data as TargetDataManagerV2AddResponse
    },
    onSuccess: () => {
      // Invalidate relevant queries to ensure cache is refreshed
      queryClient.invalidateQueries({
        queryKey: targetDataManagerKeys.patientClasses(targetDataManagerId),
        refetchType: 'active',
      })
      queryClient.invalidateQueries({
        queryKey: targetDataManagerKeys.patientsBase(targetDataManagerId),
      })
      // queryClient.invalidateQueries({queryKey:["classNames"]});

      // Additional updates to cache if necessary
    },
    onError: (error) => {
      const message = error.response?.data?.detail || 'An error occurred'
      enqueueSnackbar(message, { variant: 'error', autoHideDuration: 3000 })
    },
  })
}

export function useRemovePatientClass(targetDataManagerId: number) {
  const { backendRequest } = useAuth()
  const queryClient = useQueryClient()

  return useMutation<
    RemovePatientClassResponse,
    AxiosError<{ detail: string }>,
    RemovePatientClassRequest
  >({
    mutationFn: async (data: RemovePatientClassRequest) => {
      const response = await backendRequest({
        method: 'POST',
        endpoint: `/api/target-data-manager/remove-patient-class/${targetDataManagerId}`,
        requiresAuth: true,
        body: data,
      })
      return response.data
    },
    onSuccess: (data) => {
      enqueueSnackbar(
        `Patient class "${data.class_name}" was successfully removed.`,
        { variant: 'success' }
      )

      queryClient.invalidateQueries({
        queryKey: targetDataManagerKeys.patientsWithSampleType(
          targetDataManagerId,
          data.class_id,
          SAMPLES_TYPE.SAMPLES_TYPE_UNCLASSIFIED
        ),
      })
      queryClient.setQueryData(
        targetDataManagerKeys.patientClasses(targetDataManagerId),
        (oldData: PatientClass[] | undefined) => {
          if (!oldData) return []
          return oldData.filter((patientClass: PatientClass) => {
            return patientClass.class_name !== data.class_name
          })
        }
      )
    },
    onError: (error) => {
      enqueueSnackbar(
        error.response?.data?.detail || 'Failed to remove patient class.',
        { variant: 'error' }
      )
    },
  })
}

export function useRemovePatientsFromClass(targetDataManagerId: number) {
  const { backendRequest } = useAuth()
  const queryClient = useQueryClient()

  return useMutation<
    RemovePatientsFromClassResponse,
    AxiosError<{ detail: string }>,
    RemovePatientsFromClassRequest
  >({
    mutationFn: async (data) => {
      const response = await backendRequest({
        method: 'POST',
        endpoint: `/api/target-data-manager/${targetDataManagerId}/remove-patients-from-patient-class`,
        requiresAuth: true,
        body: data,
      })
      return response.data
    },
    onSuccess: (data) => {
      enqueueSnackbar(
        `Patients were successfuly removed ${data.patient_id_list.length} from selected class.`,
        {
          variant: 'success',
          autoHideDuration: 3000,
        }
      )

      queryClient.invalidateQueries({
        queryKey: targetDataManagerKeys.patientsWithSampleType(
          targetDataManagerId,
          data.class_id,
          SAMPLES_TYPE.SAMPLES_TYPE_CLASSIFIED
        ),
        exact: false,
      })
      // Optionally invalidate relevant queries
      // queryClient.invalidateQueries(['patient-classes', targetDataManagerId]);
      // queryClient.invalidateQueries(['patients', targetDataManagerId]);
    },
    onError: (error) => {
      enqueueSnackbar(
        error.response?.data?.detail ||
          'Failed to remove patients from the class.',
        {
          variant: 'error',
          autoHideDuration: 3000,
        }
      )
    },
  })
}

export function useUpdateTargetDataManager(targetDataManagerId: number) {
  const { backendRequest } = useAuth()
  const queryClient = useQueryClient()

  return useMutation<
    UpdateTargetDataManagerResponse,
    AxiosError<{ detail: string }>,
    UpdateTargetDataManagerRequest
  >({
    mutationFn: async (data) => {
      const response = await backendRequest({
        method: 'PUT',
        endpoint: `/api/target-data-manager/${targetDataManagerId}/info`,
        requiresAuth: true,
        body: data,
      })
      return response.data
    },
    onSuccess: (data) => {
      enqueueSnackbar(`Target Data Manager were successfuly updated.`, {
        variant: 'success',
        autoHideDuration: 3000,
      })

      queryClient.refetchQueries({
        queryKey: targetDataManagerKeys.targetDataManager(targetDataManagerId),
        exact: false,
      })

      queryClient.setQueryData(
        targetDataManagerKeys.targetDataManager(targetDataManagerId),
        (oldData: TargetOrBaseData[] | undefined) => {
          if (!oldData) return []
          return oldData.map((targetDataManager: TargetOrBaseData) => {
            if (targetDataManager.name !== data.name) {
              return {
                ...targetDataManager,
                name: data.name,
                description: data.description,
                root_efo_disease: data.root_efo_disease,
              }
            }
          })
        }
      )

      // Optionally invalidate relevant queries
      // queryClient.invalidateQueries(['patient-classes', targetDataManagerId]);
      // queryClient.invalidateQueries(['patients', targetDataManagerId]);
    },
    onError: (error) => {
      enqueueSnackbar(
        error.response?.data?.detail || 'Failed to update Target Data Manager.',
        {
          variant: 'error',
          autoHideDuration: 3000,
        }
      )
    },
  })
}

export const useSampleGroups = () => {
  const { backendRequest } = useAuth()

  return useQuery<SampleGroupResponse[], AxiosError<{ detail: string }>>({
    queryKey: ['sample-groups', 'names'],
    queryFn: async () => {
      const response = await backendRequest({
        method: 'GET',
        endpoint: '/api/sample-groups',
        requiresAuth: true,
      })
      return response.data as SampleGroupResponse[]
    },
    // Cache data for 5 minutes
    staleTime: 1000 * 60 * 5,
  })
}

export const useEfoDiseasesQuery = (diseaseName: string = '') => {
  const { logout, backendRequest } = useAuth()

  return useQuery({
    queryKey: ['efoDiseases', diseaseName],
    queryFn: async () => {
      const response = await backendRequest({
        method: 'GET',
        endpoint: `/api/disease/full-text-search?disease=${encodeURIComponent(diseaseName)}`,
        requiresAuth: true,
      })

      if (response.status === 401) {
        logout()
      }

      return response.data as EfoDisease[]
    },
    staleTime: 0,
  })
}

export const usePatientMetadataDetail = (
  patientId: number,
  fetchData: boolean = true
) => {
  const { backendRequest } = useAuth()

  return useQuery<PatientWithMetadataResponse, AxiosError<{ detail: string }>>({
    queryKey: ['patients', 'metadata', patientId],
    queryFn: async () => {
      const response = await backendRequest({
        method: 'GET',
        endpoint: `/api/patients/patient-with-metadata?patient_id=${encodeURIComponent(patientId)}`,
        requiresAuth: true,
      })

      return response.data as PatientWithMetadataResponse
    },
    enabled: Boolean(patientId) && fetchData,
  })
}

export type CreateTargetDataRequest = {
  name: string
  description: string | null
  rootEfoDisease: string
}

type CreateTargetDataResponse = {
  id: number
  name: string
  description: string
  root_efo_disease: string
}

export function useCreateTargetData() {
  // const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { backendRequest } = useAuth()

  return useMutation<
    CreateTargetDataResponse,
    AxiosError<{ detail: string }>,
    CreateTargetDataRequest
  >({
    mutationFn: async (data) => {
      const response = await backendRequest({
        method: 'POST',
        endpoint: '/api/target-data-manager/create',
        requiresAuth: true,
        body: {
          name: data.name,
          description: data.description,
          root_efo_disease: data.rootEfoDisease,
        },
      })
      return response.data as CreateTargetDataResponse
    },
    onSuccess: (response) => {
      enqueueSnackbar(
        `Target data with ID ${response.id} created successfully.`,
        { variant: 'success' }
      )
      // queryClient.invalidateQueries(targetDataManagerKeys.) // Invalidate relevant queries to refetch updated data

      navigate(`/target-data-manager/${response.id}`, {
        state: {
          name: response.name,
          description: response.description,
          rootEfoDisease: response.root_efo_disease,
        },
      })
    },
    onError: (error) => {
      enqueueSnackbar(
        error.response?.data?.detail || 'Failed to update Target Data.',
        {
          variant: 'error',
          autoHideDuration: 3000,
        }
      )
    },
  })
}

export function removeAnalysisKeysFromCache(
  queryClient: QueryClient,
  targetDataId: number,
  queryKeyPrefix: string
) {
  const queries = queryClient.getQueryCache().findAll()

  const matchingKeys = queries.filter(
    ({ queryKey }) =>
      queryKey[0] === queryKeyPrefix &&
      (queryKey[1] as { targetDataId?: number })?.targetDataId === targetDataId
  )

  matchingKeys.forEach(({ queryKey: key }) => {
    queryClient.removeQueries({ queryKey: key })
  })

  queryClient.removeQueries({
    queryKey: targetDataManagerKeys.patientAndClasses(targetDataId),
  })
}

export function removeAnalysisManagersFromCache(queryClient: QueryClient) {
  const queries = queryClient.getQueryCache().findAll()
  const matchingKeys = queries.filter(
    ({ queryKey }) => queryKey[0] === analysisManagerKeys.all
  )

  matchingKeys.forEach(({ queryKey: key }) => {
    queryClient.removeQueries({ queryKey: key })
  })
}
