import { useLocation, useParams } from 'react-router-dom'
import { Autocomplete, Box, Stack, TextField } from '@mui/material'
import { EfoDisease } from '../AnalysisManager/types'
import { useForm, Controller } from 'react-hook-form'
import { TargetDataFormInputExtended } from '../../components/TargetDataBase'
import SaveIcon from '@mui/icons-material/Save'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import {
  useEfoDiseasesQuery,
  useUpdateTargetDataManager,
} from '../TargetDataManager/components/SampleClassfier/hooks'
import { UpdateTargetDataManagerRequest } from '../TargetDataManager/components/SampleClassfier/types'
import SampleClassifier from '../TargetDataManager/components/SampleClassfier/SampleClassifier'

type StateProps = {
  name: string
  description: string
  rootEfoDisease: EfoDisease | null
}

export const TargetData: React.FC = () => {
  const { state } = useLocation() as { state: StateProps }
  const { targetDataId } = useParams<{ targetDataId: string }>()
  const updateTargetDataManager = useUpdateTargetDataManager(
    Number(targetDataId)
  )

  const { register, handleSubmit, control, watch, formState, reset } =
    useForm<TargetDataFormInputExtended>({
      defaultValues: {
        name: state.name ?? '',
        description: state.description ?? '',
        rootEfoDisease: state.rootEfoDisease ?? {
          id: 0,
          disease: '',
          ontology_id: '',
        },
      },
    })

  const rootEfoDisease = watch('rootEfoDisease') as EfoDisease

  const { isDirty, isValid } = formState
  const formValues = watch()

  const onSubmit = async () => {
    const requestParams: UpdateTargetDataManagerRequest = {
      name: formValues.name,
      description: formValues.description,
      efo_disease_id: formValues.rootEfoDisease.id,
    }
    updateTargetDataManager.mutate(requestParams, {
      onSuccess: () => reset(formValues),
    })
  }

  const efoDiseaseQuery = useEfoDiseasesQuery(rootEfoDisease.disease)

  return (
    <>
      <Stack
        className='stack wrapper-1'
        spacing={2}
        mt={-4}
        flexDirection='column'
        paddingRight={8}
        paddingLeft={15}
        overflow='hidden'
        justifyItems={'center'}
        justifyContent={'center'}
        sx={{
          maxWidth: ' clamp(16rem, 90vw, 120rem);',
          margin: 'auto',
        }}
      >
        <h1 style={{ marginBottom: '1em', marginRight: 'auto' }}>
          Target Data
        </h1>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            display='grid'
            gridTemplateColumns={{ xs: '1fr', md: '1fr 1fr 1fr max-content' }}
            gap={2}
            sx={{
              paddingBottom: '4rem',
            }}
          >
            <TextField
              {...register('name')}
              required
              id='new-target-data-name'
              label='Name'
              name='name'
            />
            <Controller
              name='rootEfoDisease'
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <Autocomplete
                  value={value}
                  options={efoDiseaseQuery.data ?? []}
                  getOptionLabel={(option) => option?.disease ?? ''}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(_, newValue) => {
                    onChange(
                      newValue ?? { id: 0, disease: '', ontology_id: '' }
                    )
                  }}
                  inputValue={value?.disease ?? ''}
                  onInputChange={(_, newInputValue) => {
                    onChange({
                      id: 0,
                      disease: newInputValue,
                      ontology_id: '',
                    })
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Root EFO Disease'
                      variant='outlined'
                    />
                  )}
                />
              )}
            />
            <TextField
              {...register('description')}
              id='new-target-data-description'
              fullWidth
              label='Description'
              name='description'
            />
            <Box display='grid'>
              <LoadingButton
                onClick={onSubmit}
                color='warning'
                loading={updateTargetDataManager.isPending}
                loadingPosition='end'
                disabled={!isDirty || !isValid}
                aria-label='save changes'
                variant='contained'
                endIcon={<SaveIcon />}
              >
                Save Changes
              </LoadingButton>
            </Box>
          </Box>
        </form>
        <SampleClassifier targetManagerId={Number(targetDataId)} />
      </Stack>
    </>
  )
}
