import React, { useEffect, useState } from 'react'

import { Skeleton, Stack, Typography } from '@mui/material'

import { useAuth } from '../context/AuthContext'
import { enqueueSnackbar } from 'notistack'
import { PrsCalculationsResponse } from './prsResults/types'

type Props = {
  isLoading: boolean
  selectedPRSMethod: string
  controlClass: string
  experimentalClass: string
  unweightedPrsResults: PrsCalculationsResponse
  weightedPrsResults: PrsCalculationsResponse
}

type RocCurveRequest = {
  control_group: number[]
  experimental_group: number[]
}

const RocCurveTooltip: React.FC<Props> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(props.isLoading)
  const [auc, setAuc] = useState<number | undefined>(undefined)

  const { backendRequest, logout } = useAuth()

  useEffect(() => {
    if (
      Object.keys(props.weightedPrsResults).length > 0 &&
      Object.keys(props.unweightedPrsResults).length > 0 &&
      props.controlClass !== '' &&
      props.experimentalClass !== ''
    ) {
      setIsLoading(true)
      const getData = async () => {
        const getPrsResultsByMethodAndClass = (
          prsResults: PrsCalculationsResponse,
          className: string,
          selectedPRSMethod: string
        ) => {
          return prsResults
            .filter(
              (patient_classes) =>
                patient_classes.patient_class.class_name === className
            )
            .map((patient_classes) =>
              patient_classes.patient_prs_results.map(
                (prsResult) => prsResult[selectedPRSMethod]
              )
            )[0]
        }

        const requestData: RocCurveRequest = {} as RocCurveRequest

        if (props.selectedPRSMethod === 'unweighted_prs') {
          requestData.control_group = getPrsResultsByMethodAndClass(
            props.unweightedPrsResults,
            props.controlClass,
            props.selectedPRSMethod
          )
          requestData.experimental_group = getPrsResultsByMethodAndClass(
            props.unweightedPrsResults,
            props.experimentalClass,
            props.selectedPRSMethod
          )
        } else {
          requestData.control_group = getPrsResultsByMethodAndClass(
            props.weightedPrsResults,
            props.controlClass,
            props.selectedPRSMethod
          )
          requestData.experimental_group = getPrsResultsByMethodAndClass(
            props.weightedPrsResults,
            props.experimentalClass,
            props.selectedPRSMethod
          )
        }

        const responseAuc = await backendRequest({
          method: 'POST',
          endpoint: '/api/prs-statistics/auc',
          requiresAuth: true,
          body: {
            control_group: requestData.control_group,
            experimental_group: requestData.experimental_group,
          },
        })

        const aucData = responseAuc.data as number

        return {
          responseAucStatus: responseAuc.status,
          aucData: aucData,
        }
      }
      getData()
        .then(({ responseAucStatus: responseAucStatus, aucData: aucData }) => {
          if (responseAucStatus === 200 && aucData) {
            setAuc(aucData)
          } else if (responseAucStatus === 401) {
            logout()
          }
        })
        .catch((error) => {
          enqueueSnackbar(`An error occurred. ${error.response?.data.detail}`, {
            variant: 'error',
          })
        })
        .finally(() => setIsLoading(false))
    }
  }, [
    props.selectedPRSMethod,
    props.weightedPrsResults,
    props.unweightedPrsResults,
  ])

  return !isLoading ? (
    <Stack spacing={1} mb={2} mt={1} mx={2}>
      <Typography variant='h6' textAlign='left'>
        Description
      </Typography>
      <Typography variant='body1' textAlign='left'>
        AUC: {auc ? auc.toFixed(4) : '-'}
      </Typography>
    </Stack>
  ) : (
    <Stack spacing={1} mb={2} mt={1} mx={2} width='15rem'>
      <Typography variant='h6' textAlign='left'>
        Description
      </Typography>
      <Skeleton variant='rounded' height='1rem' width='60%' />
    </Stack>
  )
}

export default RocCurveTooltip
