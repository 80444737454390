export const prsCalculationKeys = {
  all: (baseDataId: number, targetDataId: number) =>
    [
      'analysis',
      { baseDataId: baseDataId, targetDataId: targetDataId },
    ] as const,
  prsCalculations: (baseDataId: number, targetDataId: number) => [
    ...prsCalculationKeys.all(baseDataId, targetDataId),
    'prs-results',
  ],
  unweightedCalculations: (baseDataId: number, targetDataId: number) => [
    ...prsCalculationKeys.prsCalculations(baseDataId, targetDataId),
    'unweighted',
  ],
  weightedCalculations: (baseDataId: number, targetDataId: number) => [
    ...prsCalculationKeys.prsCalculations(baseDataId, targetDataId),
    'weighted',
  ],
}
