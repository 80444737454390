import { EfoDisease } from '../../../AnalysisManager/types'
import { Patient } from '../../../TargetDataManager/types'

// export type PatientClassWithPatientsResponse = {
//   class_id: number
//   class_name: string
//   patients_with_sample_group: Patient[]
// }

export type PatientClassWithPatientsResponse = {
  class_id: number
  class_name: string
  patients_with_sample_group: Patient[]
  next_cursor: number | null
  prev_cursor: number | null
  has_more: boolean
  has_previous: boolean
  total: number
}

export type TargetData = {
  filters: FilterOptions
  pagination: PaginationOptions
}

export type PaginationOptions = {
  lastId: number | null
  prevId: number | null
  pageSize: number
}

export type TargetManagerTableOptions = {
  targetManagerId: number
  classId: number
}

export enum SAMPLES_TYPE {
  'SAMPLES_TYPE_UNCLASSIFIED',
  'SAMPLES_TYPE_CLASSIFIED',
}

// export interface PaginationOptions {
//   lastId: number | null;
//   prevId: number | null;
//   pageSize: number;
// }

export interface FilterOptions {
  sample_name: string | null
  group_name: string | null
  metadata: Record<string, unknown> | null
}

// 4) Zložené parametre pre query
export interface PatientAndClassesQueryParams {
  pagination: PaginationOptions
  filters: FilterOptions
}

// 5) Samotný query key
export type PatientAndClassesQueryKey = [
  'patientAndClasses',
  PatientAndClassesQueryParams,
]

// requests and responses
export interface RemovePatientsFromClassRequest {
  class_name: string
  patient_id_list: number[]
  select_all: boolean
  filters?: {
    sample_name?: string | null
    group_name?: string | null
    select_all?: boolean
  } // Optional filters for unclassified or classified
}

export interface Params {
  last_id: number | null
  prev_id: number | null
  page_size: number
  class_id?: number
}

export interface UsePatientAndClassesPaginatedOptions {
  targetDataId: number
  classId: number
  options: TargetData
  sampleType?: SAMPLES_TYPE
}

export interface UseClassNamesOptions {
  targetManagerId: number
}

export interface CreatePatientClassInput {
  targetDataManagerId: number
  className: string
}

export interface CreatePatientClassResponse {
  id: number
  class_name: string
}

export interface TargetDataManagerV2AddResponse {
  name: string
  patients: Patient[]
}
export interface ManagePatientsRequest {
  source_class_name?: string
  target_class_name: string
  patient_ids?: number[] // Optional to support select_all
  select_all: boolean // New option for "Select All"
  create_new_class?: boolean
  filters?: FilterOptions // Optional filters for unclassified or classified
}

export interface RemovePatientClassRequest {
  class_name: string
}

export interface RemovePatientClassResponse {
  class_id: number
  class_name: string
}

export interface RemovePatientsFromClassResponse {
  class_id: number
  patient_id_list: number[]
}

export type UpdateTargetDataManagerRequest = {
  name: string
  description: string
  efo_disease_id: number
}

export type UpdateTargetDataManagerResponse = {
  name: string
  description: string
  root_efo_disease: EfoDisease
}

export type SampleGroupResponse = {
  id: number
  name: string
  created_at: string
  updated_at: string
}

export type PatientWithMetadataResponse = {
  sample_name: string
  patient_metadata: Record<string, unknown>
}
