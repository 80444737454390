import React, { useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  Typography,
  TextField,
} from '@mui/material'
import { SubmitHandler, useForm, Controller } from 'react-hook-form'
import { iconsObj } from '../../icons/Icons'
import SubmitButton from '../buttons/SubmitButton'
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query'
import Autocomplete from '@mui/material/Autocomplete'
import { EfoDisease } from '../../pages/AnalysisManager/types'
import {
  CreateTargetDataRequest,
  useCreateTargetData,
  useEfoDiseasesQuery,
} from '../../pages/TargetDataManager/components/SampleClassfier/hooks'

const deafultValues = {
  defaultValues: {
    name: '',
    description: '',
    rootEfoDisease: {
      id: 0,
      disease: '',
      ontology_id: '',
    },
  },
}

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  refetchTable: (
    options?: RefetchOptions
  ) => Promise<QueryObserverResult<unknown, Error>>
}

interface TargetDataFormInput {
  name: string
  description: string
  rootEfoDisease: EfoDisease
}

const NewTargetDataDialog: React.FC<Props> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const {
    register,
    handleSubmit,
    reset,
    formState,
    control,
    setValue,
    watch,
    trigger,
  } = useForm<TargetDataFormInput>(deafultValues)

  const rootEfoDisease = watch('rootEfoDisease') as EfoDisease

  const handleClose = () => {
    props.setOpen(false)
    reset()
  }

  const { isDirty, isValid } = formState

  const efoDiseaseQuery = useEfoDiseasesQuery(rootEfoDisease.disease)
  const createTargetData = useCreateTargetData()
  const onSubmit: SubmitHandler<TargetDataFormInput> = async (data) => {
    setIsLoading(true)
    const request: CreateTargetDataRequest = {
      name: data.name,
      description: data.description,
      rootEfoDisease: data.rootEfoDisease.disease,
    }
    createTargetData.mutate(request)
    setIsLoading(false)
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth='sm'
        open={props.open}
        onClose={handleClose}
        aria-labelledby='new-target-data-dialog'
        aria-describedby='new-target-data-dialog'
      >
        <DialogTitle>
          <Stack direction='row' spacing={1}>
            <Paper elevation={0} sx={{ backgroundColor: 'inherit', pt: 0.5 }}>
              {iconsObj.TARGET_DATA}
            </Paper>
            <Typography variant='h5'>New Target Data</Typography>
          </Stack>
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          {iconsObj.CLOSE}
        </IconButton>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}
        >
          <TextField
            label='Name'
            variant='outlined'
            {...register('name', { required: true })}
            fullWidth
          />

          <Controller
            name='rootEfoDisease'
            control={control}
            rules={{
              required: 'Root EFO Disease is required',
              validate: (value) =>
                (value && value.disease != '') ||
                'Please select a valid EFO disease',
            }}
            render={() => (
              <Autocomplete
                options={efoDiseaseQuery.isSuccess ? efoDiseaseQuery.data : []}
                // We show disease name to the user
                getOptionLabel={(option) => option.disease}
                onChange={(event, newValue) => {
                  setValue(
                    'rootEfoDisease',
                    newValue ?? { id: 0, disease: '', ontology_id: '' }
                  )
                  trigger('rootEfoDisease')
                }}
                // inputValue is what the user types, value is the selected option
                inputValue={rootEfoDisease.disease}
                onInputChange={(event, newValue) => {
                  setValue('rootEfoDisease', {
                    id: 0,
                    disease: newValue || '',
                    ontology_id: '',
                  })
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Root EFO Disease'
                    variant='outlined'
                  />
                )}
              />
            )}
          />

          <TextField
            label='Description'
            variant='outlined'
            {...register('description', { required: false })}
            fullWidth
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions sx={{ mx: 2.5, mb: 2, mt: 3, justifyContent: 'center' }}>
          <SubmitButton
            id='create-new-target-data-btn'
            text='Submit'
            fullWidth
            disabled={!isDirty || !isValid}
            startIcon={iconsObj.SUBMIT}
            onClick={handleSubmit(onSubmit)}
            loading={isLoading}
          />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default NewTargetDataDialog
