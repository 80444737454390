import {
  ColumnDef,
  ColumnFiltersState,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  RowSelectionState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { PaginationOptions } from './types'
import { Patient } from '../../../TargetDataManager/types'

export const useTableConfig = (
  pagination: PaginationOptions,
  columns: ColumnDef<Patient>[]
) => {
  const [data, setData] = useState<Patient[]>([])

  const [expanded, setExpanded] = useState<ExpandedState>({})
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

  const getFilterValue = (id: string): string | undefined => {
    const filter = columnFilters.find((f) => f.id === id)
    return filter?.value as string | undefined
  }

  const defaultData = useMemo(() => [], [])
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    getCoreRowModel: getCoreRowModel(),
    // Row expand
    getExpandedRowModel: getExpandedRowModel(),
    // Filtering
    getFilteredRowModel: getFilteredRowModel(),
    // Sorting
    getSortedRowModel: getSortedRowModel(),

    getRowCanExpand: () => true,
    getRowId: (row) => row.id.toString(),

    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onExpandedChange: setExpanded,
    onColumnFiltersChange: setColumnFilters,

    // Turn off client-side pagination
    manualPagination: true,
    enableRowSelection: true,

    data: data ?? defaultData,
    columns,
    state: {
      expanded,
      columnFilters,
      rowSelection,
      sorting,
      ...pagination, //pagination options
    },
    debugTable: false,
  })
  return {
    table: table,
    getFilterValue: getFilterValue,
    setData: setData,
    setExpanded: setExpanded,
    setColumnFilters: setColumnFilters,
    setRowSelection: setRowSelection,
    setSorting: setSorting,
  }
}
