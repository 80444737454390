import React, { useMemo, useState } from 'react'
import TablePageSkeleton from '../../components/TablePageSkeleton'
import PageHeading from '../../components/PageHeading'
import { iconsObj } from '../../icons/Icons'
import { Button, ButtonGroup } from '@mui/material'
import CustomDataGrid from '../../components/CustomDataGrid'
import { DataGridProps, GridRenderCellParams } from '@mui/x-data-grid'
import TableIconButton from '../../components/buttons/TableIconButton'
import BackendRequestDialog from '../../components/dialogs/BackendRequestDialog'
import NewBaseDataDialog from '../../components/dialogs/NewBaseDataDialog'
import BaseDataDialog from '../../components/dialogs/BaseDataDialog'
import { useIsMutating } from '@tanstack/react-query'
import { useBaseDataManager } from './hooks'
import { enqueueSnackbar } from 'notistack'
import { TargetOrBaseData } from '../TargetDataManager/types'

type ExtendedBaseData = TargetOrBaseData & {
  actions: React.ReactNode
}

const BaseDataManager: React.FC = () => {
  const [selectedBaseDatatId, setSelectedBaseDatatId] = useState<number>(0)
  const [showRemoveDialog, setShowRemoveDialog] = useState<boolean>(false)
  const [showBaseDataDialog, setShowBaseDataDialog] = useState<boolean>(false)
  const [showAddBaseDataDialog, setShowAddBaseDataDialog] =
    useState<boolean>(false)

  const isMutating = useIsMutating()
  const baseDatamanager = useBaseDataManager()

  const columnSpec = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        editable: false,
        headerAlign: 'left',
        align: 'left',
      },
      {
        field: 'name',
        headerName: 'Name',
        editable: false,
        headerAlign: 'left',
        align: 'left',
        flex: 1,
      },
      {
        field: 'description',
        headerName: 'Description',
        editable: false,
        headerAlign: 'left',
        align: 'left',
        flex: 1,
      },
      {
        field: 'disease_trait',
        headerName: 'Disease / Trait',
        editable: false,
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        renderCell: (params: GridRenderCellParams) =>
          params.row.root_efo_disease.disease,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        width: 150,
        filterable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => params.row.actions,
      },
    ],
    []
  )

  let actions: ExtendedBaseData[] = []
  if (baseDatamanager.isSuccess) {
    actions = baseDatamanager.data.map((baseData: TargetOrBaseData) => {
      const actions = (
        <ButtonGroup>
          <TableIconButton
            icon={iconsObj.OPEN}
            size='small'
            tooltipText='Open Base Data'
            id='open_base_data_btn'
            onClick={() => {
              setSelectedBaseDatatId(baseData.id)
              setShowBaseDataDialog(true)
            }}
          />
          <TableIconButton
            icon={iconsObj.DELETE}
            size='small'
            tooltipText='Delete Base Data'
            id='delete_base_data_btn'
            onClick={() => {
              setSelectedBaseDatatId(baseData.id)
              setShowRemoveDialog(true)
            }}
          />
        </ButtonGroup>
      )
      return { ...baseData, actions }
    })
  } else if (baseDatamanager.isError) {
    enqueueSnackbar(`An error occurred. ${baseDatamanager.error.message}`, {
      variant: 'error',
    })
  }

  return (
    <React.Fragment>
      <TablePageSkeleton
        headerGridLeft={
          <PageHeading icon={iconsObj.BASE_DATA}>Base Data Manager</PageHeading>
        }
        headerGridRight={
          <Button
            variant='outlined'
            startIcon={iconsObj.ADD}
            onClick={() => setShowAddBaseDataDialog(true)}
          >
            Add Base Data
          </Button>
        }
        table={
          <CustomDataGrid
            rows={baseDatamanager.isSuccess ? actions : []}
            columns={columnSpec as DataGridProps['columns']}
            isLoading={
              baseDatamanager.isLoading ||
              (baseDatamanager.isRefetching &&
                baseDatamanager.isFetchedAfterMount) ||
              isMutating > 0
            }
          />
        }
      />
      <BackendRequestDialog
        show={showRemoveDialog}
        setShow={setShowRemoveDialog}
        handleSuccess={() => {
          baseDatamanager.refetch()
        }}
        endpoint={`/api/base-data-manager/${selectedBaseDatatId}`}
        requestMethod='DELETE'
        title={
          'Are you sure you want to remove Base Data ' +
          selectedBaseDatatId +
          '?'
        }
        buttonText='Remove'
        buttonColor='error'
        buttonIcon={iconsObj.DELETE}
        id='remove-base-data-dialog'
      />
      <NewBaseDataDialog
        open={showAddBaseDataDialog}
        setOpen={setShowAddBaseDataDialog}
        setReloadTable={baseDatamanager.refetch}
      />
      {showBaseDataDialog && (
        <BaseDataDialog
          open={showBaseDataDialog}
          setOpen={setShowBaseDataDialog}
          baseDataId={selectedBaseDatatId}
          setReloadTable={baseDatamanager.refetch}
        />
      )}
    </React.Fragment>
  )
}

export default BaseDataManager
