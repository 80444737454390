import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { iconsObj } from '../../icons/Icons'
import FormInputRadioGroup from '../forms/FormInputRadioGroup'
import SubmitButton from '../buttons/SubmitButton'
import MultipleSelectCheckmarks from '../forms/MultipleSelectCheckmarks'
import { GeneType } from '../../pages/PathwayBasedAnalysis'
import { Option } from '../forms/FormProps'

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  geneTypes: GeneType[]
  setShowPRSDialogDialog: (showPRSDialog: boolean) => void
  handleSubmitClose?: (
    includeGeneSnps: boolean,
    includeGeneRegionSnps: boolean,
    selectedGeneTypes: Option[]
  ) => void
}

type FormInput = {
  snpsLyingOnGenes: string
  snpsLyingOnGeneRegions: string
}

const PathwayBasedPrsSettingsDialog: React.FC<Props> = (props) => {
  const [excludedGeneTypes, setExcludedGeneTypes] = useState<Option[]>([])

  const { handleSubmit, reset, control } = useForm<FormInput>()

  const handleClose = () => {
    props.setOpen(false)
    reset()
  }

  const onSubmit: SubmitHandler<FormInput> = async (data) => {
    handleClose()
    props.setShowPRSDialogDialog(true)

    if (props.handleSubmitClose) {
      const includeGeneSnps = data.snpsLyingOnGenes === 'yes'
      const includeGeneRegionSnps = data.snpsLyingOnGeneRegions === 'yes'
      props.handleSubmitClose(
        includeGeneSnps,
        includeGeneRegionSnps,
        excludedGeneTypes
      )
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      open={props.open}
      onClose={handleClose}
      aria-labelledby='pathway-prs-settings-dialog'
      aria-describedby='pathway-prs-settings-dialog'
    >
      <DialogTitle>
        <Stack direction='row' spacing={1}>
          <Paper elevation={0} sx={{ backgroundColor: 'inherit', pt: 0.5 }}>
            {iconsObj.SETTINGS}
          </Paper>
          <Typography variant='h5'> Pathway Based PRS Settings</Typography>
        </Stack>
      </DialogTitle>
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        {iconsObj.CLOSE}
      </IconButton>
      <DialogContent>
        <Stack spacing={4} px={1} sx={{ width: '100%' }}>
          <FormInputRadioGroup
            required
            id='pathway-prs-settings-snps-lying-on-genes'
            label='Include SNPs lying on genes'
            name='snpsLyingOnGenes'
            control={control}
            defaultValue={'yes'}
            options={[
              { value: 'yes', id: 'yes-include-genes', label: 'Yes' },
              { value: 'no', id: 'no-include-genes', label: 'No' },
            ]}
          />
          <FormInputRadioGroup
            required
            id='pathway-prs-settings-snps-lying-on-gene-regions'
            label='Include SNPs lying on gene regions'
            name='snpsLyingOnGeneRegions'
            control={control}
            defaultValue={'yes'}
            options={[
              { value: 'yes', id: 'yes-gene-regions', label: 'Yes' },
              { value: 'no', id: 'no-gene-regions', label: 'No' },
            ]}
          />
          <MultipleSelectCheckmarks
            fullWidth
            label='Excluded gene types'
            options={props.geneTypes.map((geneType) => ({
              id: geneType.id,
              label: geneType.type,
            }))}
            defaultValue={[]}
            onChange={(data) => setExcludedGeneTypes(data)}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ mx: 3, mb: 2, mt: 3, justifyContent: 'center' }}>
        <SubmitButton
          id='submit-pathway-prs-settings-btn'
          text='Submit'
          fullWidth
          onClick={handleSubmit(onSubmit)}
          startIcon={iconsObj.SUBMIT}
        />
      </DialogActions>
    </Dialog>
  )
}

export default PathwayBasedPrsSettingsDialog
