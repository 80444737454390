import {
  Collapse,
  IconButton,
  TextField,
  Typography,
  Button,
  List,
  ListItemButton,
  Checkbox,
  Box,
} from '@mui/material'
import './td.css'
import { useEffect, useMemo, useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import {
  FilterOptions,
  SAMPLES_TYPE,
  TargetData,
  TargetManagerTableOptions,
} from './types'
import { useClassNames, usePatientAndClassesPaginated } from './hooks'
import { ColumnDef, flexRender } from '@tanstack/react-table'
import React from 'react'
import SelectAllRowsPrompt from './components/SelectAllRowsPrompt'
import { ManagePatientsDialog } from './components/ManagePatientsDialog'
import PatientClassItem from './components/PatientClassItem'
import AddPatientClassDialog from './components/AddPatientClassDialog'
import DeleteClassDialog from './components/DeleteClassDialog'
import { defaultPageSize, Footer } from './components/Footer'
import { useTableConfig } from './tableConfig'
import { TableFilters } from './components/TableFilters'
import { PatientMetadataDetail } from './components/PatientMetadataDetail'
import { MetadataView } from './components/MetadataView'
import { Patient } from '../../../TargetDataManager/types'

type Props = {
  targetManagerId: number
}

const SampleClassifier: React.FC<Props> = ({ targetManagerId }) => {
  const [sampleType, setSampleType] = useState<SAMPLES_TYPE>(
    SAMPLES_TYPE.SAMPLES_TYPE_UNCLASSIFIED
  )

  const [managePatientsDialogOpen, managePatientsDialogSetOpen] =
    useState(false)

  const [searchTerm, setSearchTerm] = useState('')

  const [selectAll, setSelectAll] = useState<boolean>(false) // State for global selection
  const [showSelectAllMessage, setShowSelectAllMessage] =
    useState<boolean>(false) // Display message for selecting all items
  const [selectedIds, setSelectedIds] = useState<Set<number>>(new Set()) // Individually selected IDs

  const [pageSize, setPageSize] = useState(defaultPageSize)

  // State for TanStack Table's Pagination
  const [pageIndex, setPageIndex] = useState(0)

  const [targetManagerTableOptions, setTargetManagerTableOptions] =
    useState<TargetManagerTableOptions>({
      targetManagerId: targetManagerId,
      classId: 0,
    })

  const handleAllSelection = () => {
    setSelectAll(true)
  }

  const handleClearAllSelection = () => {
    setSelectAll(false)
    setShowSelectAllMessage(false)
  }

  // function handlePaginationChange(updatePagination: Partial<PaginationOptions>) {
  //   setPagination((prev) => ({
  //     ...prev,
  //     pagination: {
  //       ...prev.pagination,
  //       lastId: null,
  //       prevId: null,
  //       updatePagination
  //     },
  //   }))
  // }

  function handleFilterchange(
    updatedFilters: Partial<FilterOptions>,
    resetPage = false
  ) {
    setPatientAndClassesOptions((prevPagination) => {
      let paginationCpy = prevPagination.pagination

      if (resetPage) {
        paginationCpy = {
          ...prevPagination.pagination,
          prevId: null,
          lastId: null,
        }
      }

      return {
        pagination: paginationCpy,
        filters: {
          ...prevPagination.filters,
          ...updatedFilters,
        },
      }
    })
  }

  function handleTabSelect(selectedClassId: number, sampleType: SAMPLES_TYPE) {
    setTargetManagerTableOptions((prevOptions) => ({
      ...prevOptions,
      classId: selectedClassId,
    }))
    setPageIndex(0)
    setPageSize(defaultPageSize)
    setPatientAndClassesOptions({
      pagination: {
        lastId: null, //initial page index
        pageSize: defaultPageSize, //default page size
        prevId: null,
      },
      filters: {
        group_name: null,
        sample_name: null,
        metadata: null,
      },
    })

    setSampleType(sampleType)

    setSelectAll(false)
    setShowSelectAllMessage(false)
    setExpanded({})
    setColumnFilters([])
    setRowSelection({})
    setSorting([])
    setSelectedIds(new Set())
  }

  function isTabSelected(selectedClassId: number) {
    if (sampleType == SAMPLES_TYPE.SAMPLES_TYPE_UNCLASSIFIED) {
      return selectedClassId == 0
    }
    return targetManagerTableOptions.classId === selectedClassId
  }

  const [patientAndClassesOptions, setPatientAndClassesOptions] =
    useState<TargetData>({
      pagination: {
        lastId: null, //initial page index
        pageSize: pageSize, //default page size
        prevId: null,
      },
      filters: {
        group_name: null,
        sample_name: null,
        metadata: null,
      },
    })

  const patientAndClassesPaginatedResponse = usePatientAndClassesPaginated({
    targetDataId: targetManagerTableOptions.targetManagerId,
    classId: targetManagerTableOptions.classId,
    options: patientAndClassesOptions,
    sampleType,
  })

  const selectedSampleCount = selectAll
    ? patientAndClassesPaginatedResponse.data?.total ?? 0
    : selectedIds.size
  const classNames = useClassNames({ targetManagerId })
  const filteredClasses = classNames.data?.filter((patientClass) =>
    patientClass.class_name.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const columns = useMemo<ColumnDef<Patient>[]>(
    () => [
      {
        enableResizing: false,
        accessorKey: 'sample_name',
        header: ({ table }) => (
          <div
            className='cell-value'
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                checked={selectAll || table.getIsAllPageRowsSelected()}
                indeterminate={
                  table.getIsSomePageRowsSelected() ||
                  (selectAll && selectedIds.size > 0)
                }
                onChange={(event) => {
                  const isChecked = event.target.checked
                  if (isChecked) {
                    // Select all rows on current page
                    table.toggleAllPageRowsSelected(true)
                    setShowSelectAllMessage(true)
                    const pageRowIds = table
                      .getRowModel()
                      .rows.map((row) => row.original.id)
                    setSelectedIds((prev) => {
                      return new Set([...prev, ...pageRowIds])
                    })
                  } else {
                    // Uncheck all rows on current page
                    table.toggleAllPageRowsSelected(false)

                    setSelectAll(false)
                    setShowSelectAllMessage(false)

                    // Remove IDs of rows on current page from selectedIds
                    const pageRowIds = table
                      .getRowModel()
                      .rows.map((row) => row.original.id)
                    setSelectedIds((prev) => {
                      const newSet = new Set(prev)
                      pageRowIds.forEach((id) => newSet.delete(id))
                      return newSet
                    })
                  }
                }}
                inputProps={{ 'aria-label': 'Select All Samples' }}
              />
              <Typography variant='subtitle1'>Name</Typography>
            </div>
          </div>
        ),
        cell: ({ getValue, row }) => (
          <div
            className='cell-value'
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Checkbox
              checked={selectAll ? true : row.getIsSelected()}
              indeterminate={false}
              onChange={(event) => {
                const isChecked = event.target.checked

                if (selectAll) {
                  // If global selection is active and user unchecks a row, deactivate global selection
                  if (!isChecked) {
                    setSelectAll(false)
                    setShowSelectAllMessage(false)

                    const selectedRowIds = table
                      .getSelectedRowModel()
                      .rows.filter((r) => r.original.id !== row.original.id)
                      .map((r) => r.original.id)
                    setSelectedIds(new Set(selectedRowIds))
                    row.toggleSelected(isChecked)
                  }
                  row.toggleSelected(isChecked)
                } else {
                  if (isChecked) {
                    setSelectedIds((prev) => {
                      return new Set(prev).add(row.original.id)
                    })
                  } else {
                    setSelectedIds((prev) => {
                      const newSet = new Set(prev)
                      newSet.delete(row.original.id)

                      return newSet
                    })
                  }

                  row.toggleSelected(isChecked)
                }
              }}
            />
            <Typography className='cell-value' variant='body2'>
              {getValue<string>().replace('.gvcf.gz', '')}
            </Typography>
          </div>
        ),
      },
      {
        accessorKey: 'sample_group',
        header: () => (
          <div
            className='cell-value'
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <Typography variant='subtitle1'>Sample Group</Typography>
          </div>
        ),
        cell: (props) => (
          <Typography className='cell-value' variant='body2'>
            {props.getValue<string>()}
          </Typography>
        ),
      },
      // {
      //   accessorKey: 'description',
      //   header: ({ column }) => (
      //     <div style={{ display: 'flex', flexDirection: 'column' }}>
      //       <Typography variant='subtitle1'>Description</Typography>
      //       <TextField
      //         variant='standard'
      //         value={(column.getFilterValue() ?? '') as string}
      //         onChange={(e) => column.setFilterValue(e.target.value)}
      //         placeholder='Search Description'
      //         fullWidth
      //         InputProps={{
      //           disableUnderline: true,
      //           style: { fontSize: '0.8rem' },
      //         }}
      //       />
      //     </div>
      //   ),
      //   cell: (props) => <Typography variant='body2'>{props.getValue<string>()}</Typography>,
      // },
      {
        accessorKey: 'created_at',
        header: () => (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant='subtitle1'>Created At</Typography>
          </div>
        ),
        cell: (props) => {
          const created_at = props.getValue<Date>()
          return (
            <Typography variant='body2'>
              {new Date(created_at).toLocaleDateString()}
            </Typography>
          )
        },
      },
      {
        accessorKey: 'updated_at',
        header: () => (
          <div
            className='cell-value'
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <Typography variant='subtitle1'>Updated At</Typography>
          </div>
        ),
        cell: (props) => {
          const updated_at = props.getValue<Date>()
          return (
            <Typography className='cell-value' variant='body2'>
              {new Date(updated_at).toLocaleDateString()}
            </Typography>
          )
        },
      },
      {
        accessorKey: 'expand',
        enableSorting: false,
        header: ({ table }) => (
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={table.getToggleAllRowsExpandedHandler()}
          >
            {table.getIsAllRowsExpanded() ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        ),
        cell: ({ row }) => (
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={row.getToggleExpandedHandler()}
          >
            {row.getIsExpanded() ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        ),
        enableResizing: false,
      },
    ],
    [
      sampleType,
      patientAndClassesPaginatedResponse.data?.class_name,
      selectedSampleCount,
    ]
  )

  useEffect(() => {
    if (patientAndClassesPaginatedResponse.isSuccess) {
      setData(
        patientAndClassesPaginatedResponse.data.patients_with_sample_group
      )
    }
  }, [patientAndClassesPaginatedResponse.data])

  const {
    table,
    setData,
    setExpanded,
    setColumnFilters,
    setRowSelection,
    setSorting,
  } = useTableConfig(patientAndClassesOptions.pagination, columns)

  const handleClassifiedSamplesTabSelect = (className: string) => {
    const selectedClass = classNames.data?.find(
      (patientClass) => patientClass.class_name === className
    )

    if (!selectedClass) {
      return
    }
    handleTabSelect(selectedClass.id, SAMPLES_TYPE.SAMPLES_TYPE_CLASSIFIED)
  }

  return patientAndClassesPaginatedResponse.isSuccess ? (
    <>
      <div className='page-container'>
        <nav className='patient-class-navbar'>
          <div className='nav-section'>
            <Typography
              className='heading-color'
              style={{ paddingBottom: '1rem' }}
            >
              UNCLASSIFIED SAMPLES
            </Typography>
            <ListItemButton
              className='class-item'
              style={{ borderRadius: '10px', paddingBlock: '1em' }}
              onClick={() => {
                handleTabSelect(0, SAMPLES_TYPE.SAMPLES_TYPE_UNCLASSIFIED)
              }}
              selected={isTabSelected(0)}
            >
              Samples
            </ListItemButton>
          </div>
          <div className='nav-section'>
            <AddPatientClassDialog targetManagerId={targetManagerId} />
            <TextField
              variant='outlined'
              size='small'
              placeholder='Search classes'
              onChange={(e) => setSearchTerm(e.target.value)}
              value={searchTerm}
              sx={{ marginBottom: 2, width: '100%' }}
            />{' '}
            <List className='class-list' style={{ paddingRight: '.4em' }}>
              {filteredClasses !== undefined &&
                filteredClasses.map((patientClass) => (
                  <ListItemButton
                    className='class-item'
                    style={{
                      borderBottom: '1px solid var(--border-clr)',
                      minWidth: 'max-content',
                      paddingBlock: '1em',
                      borderRadius: '10px',
                    }}
                    selected={isTabSelected(patientClass.id)}
                    onClick={() => {
                      handleTabSelect(
                        patientClass.id,
                        SAMPLES_TYPE.SAMPLES_TYPE_CLASSIFIED
                      )
                    }}
                    sx={{
                      display: 'grid',
                      gridAutoFlow: 'column',
                      justifyContent: 'space-between',
                    }}
                  >
                    <PatientClassItem
                      key={patientClass.id}
                      class_name={patientClass.class_name}
                      targetDataManagerId={targetManagerId}
                      handleTabSelect={handleTabSelect}
                    />
                  </ListItemButton>
                ))}
            </List>
          </div>
        </nav>
        <div className='container'>
          <div className='table-wrapper'>
            <div className='table-hero'>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <h2>
                  {patientAndClassesPaginatedResponse.data.class_name ??
                    'Unclassified Samples'}
                </h2>
              </Box>
              <div className='btn-control-group'>
                <Button
                  onClick={() => managePatientsDialogSetOpen(true)}
                  variant='outlined'
                  disabled={!selectAll && selectedSampleCount === 0}
                >
                  Classify Samples
                </Button>
                {sampleType === SAMPLES_TYPE.SAMPLES_TYPE_CLASSIFIED && (
                  <DeleteClassDialog
                    targetManagerId={targetManagerId}
                    className={
                      patientAndClassesPaginatedResponse.data?.class_name ?? ''
                    }
                    selectedSamplesCount={selectedSampleCount}
                    selectedPatients={Array.from(selectedIds)}
                    selectAllPatients={selectAll}
                    targetData={patientAndClassesOptions}
                  />
                )}
              </div>
              <TableFilters
                table={table}
                setPageIndex={setPageIndex}
                setPagination={setPatientAndClassesOptions}
                setSelectAll={setSelectAll}
                handleFilterchange={handleFilterchange}
              />
            </div>
            <div className='table-header'>
              {table.getHeaderGroups().map((headerGroup) => (
                <div
                  className='table-row'
                  data-type='row-header'
                  key={headerGroup.id}
                >
                  {headerGroup.headers.map((header) => (
                    <div className='table-cell' key={header.id}>
                      {header.isPlaceholder ? null : (
                        <>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <div className='table-body'>
              {showSelectAllMessage &&
                Array.from(selectedIds).length <
                  patientAndClassesPaginatedResponse.data.total && (
                  <SelectAllRowsPrompt
                    currentPageAllRowsSelected={table.getIsAllRowsSelected()}
                    selectAll={selectAll}
                    selectedRowIds={selectedIds}
                    allSampleCount={
                      patientAndClassesPaginatedResponse.data.total
                    }
                    handleAllSelection={handleAllSelection}
                    handleClearAllSelection={handleClearAllSelection}
                  />
                )}
              {}
              {table.getRowModel().rows.length === 0 ? (
                <Typography variant='h5' className='table-body-empty-message'>
                  No Samples Available
                </Typography>
              ) : (
                table.getRowModel().rows.map((row) => (
                  <React.Fragment key={row.id}>
                    <div
                      className='table-row'
                      style={{
                        borderBottom: !row.getIsExpanded()
                          ? 'none'
                          : '1px solid rgba(224, 224, 224, 1)',
                      }}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <div className='table-cell' key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </div>
                      ))}
                    </div>
                    <div
                      style={{
                        borderBottom: !row.getIsExpanded()
                          ? 'none'
                          : '1px solid rgba(224, 224, 224, 1)',
                      }}
                    >
                      <Collapse
                        className='collapsible-table-row'
                        in={row.getIsExpanded()}
                        timeout='auto'
                        unmountOnExit
                      >
                        <div className='collapsible-metadata'>
                          {/* Metadata preview */}
                          <MetadataView
                            patient_metadata={row.original.patient_metadata}
                          />
                          {/* Metadata detailed view */}
                          <PatientMetadataDetail patientId={row.original.id} />
                        </div>
                      </Collapse>
                    </div>
                  </React.Fragment>
                ))
              )}
            </div>
            <Footer
              pageIndex={pageIndex}
              sampleCount={patientAndClassesPaginatedResponse.data.total}
              selectedSampleCount={selectedSampleCount}
              samplesData={patientAndClassesPaginatedResponse.data}
              pageSize={pageSize}
              setPageSize={setPageSize}
              setShowSelectAllMessage={setShowSelectAllMessage}
              setSelectAll={setSelectAll}
              setPagination={setPatientAndClassesOptions}
              setPageIndex={setPageIndex}
            />
          </div>
        </div>
      </div>
      {/* <DialogCreatePatientClass open={dialogCreatePatientClassOpen} setOpen={dialogCreatePatientClassSetOpen} targetManagerId={targetManagerId} /> */}
      <ManagePatientsDialog
        open={managePatientsDialogOpen}
        setOpen={managePatientsDialogSetOpen}
        targetDataManagerId={targetManagerId}
        patientClasses={classNames.data ?? []}
        selectAll={selectAll}
        selectedIds={Array.from(selectedIds)}
        sourceClassName={patientAndClassesPaginatedResponse.data.class_name}
        selectedSamplesCount={selectedSampleCount}
        currentSampleType={sampleType}
        handleClassifiedSamplesTabSelect={handleClassifiedSamplesTabSelect}
        targetData={patientAndClassesOptions}
      />
    </>
  ) : (
    <div className=''></div>
  )
}

export default SampleClassifier
