import React, { useState } from 'react'
import Stack from '@mui/material/Stack'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'

import { iconsObj } from '../../icons/Icons'
import { useAuth } from '../../context/AuthContext'
import SubmitButton from '../buttons/SubmitButton'
import { IconButton } from '@mui/material'
import { enqueueSnackbar } from 'notistack'

type Props = {
  show: boolean
  setShow: (show: boolean) => void
  title: string
  endpoint: string
  requestMethod: 'DELETE' | 'POST' | 'PUT'
  text?: string
  buttonText: string
  buttonColor?:
    | 'error'
    | 'success'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'warning'
    | 'info'
  buttonIcon?: React.ReactElement
  id: string
  handleClose?: () => void // happens on every close
  handleSuccess?: () => void // happens on close with a successful endpoint request
  reloadSite?: boolean
  children?: React.ReactNode
}

const BackendRequestDialog: React.FC<Props> = ({
  show,
  setShow,
  children,
  title,
  text = '',
  buttonText,
  buttonColor = 'primary',
  buttonIcon = iconsObj.SUBMIT,
  endpoint,
  requestMethod,
  id,
  handleClose,
  handleSuccess,
  reloadSite = false,
}) => {
  const [loading, setLoading] = useState(false)

  const { backendRequest, logout } = useAuth()

  const onClose = () => {
    setShow(false)
    if (handleClose !== undefined) {
      handleClose()
    }
  }

  const onClick = async () => {
    setLoading(true)

    const getResponse = async () => {
      const response = await backendRequest({
        method: requestMethod,
        endpoint: endpoint,
        requiresAuth: true,
      })

      return {
        status: response.status,
        statusText: response.statusText,
        data: response.data,
      }
    }
    getResponse()
      .then((response) => {
        if (response && (response.status === 200 || response.status === 204)) {
          if (handleSuccess !== undefined) {
            handleSuccess()
          }
          onClose()
          if (reloadSite) {
            window.location.reload()
          }
        } else if (response.status === 401) {
          logout()
        }
      })
      .catch((error) => {
        enqueueSnackbar(`An error occurred. ${error.response?.data.detail}`, {
          variant: 'error',
        })
      })
      .finally(() => setLoading(false))
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth='sm'
      open={show}
      onClose={onClose}
      aria-labelledby={`modal-${id}`}
      aria-describedby={`modal-${id}`}
    >
      <DialogTitle>{title}</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        {iconsObj.CLOSE}
      </IconButton>
      <DialogContent>
        {text.length > 0 && (
          <DialogContentText marginBottom={2}>{text}</DialogContentText>
        )}
        {children && (
          <Stack display='flex' alignItems='center' paddingTop={1}>
            {children}
          </Stack>
        )}
      </DialogContent>
      <DialogActions sx={{ mx: 2, mb: 2 }}>
        <SubmitButton
          onClick={onClick}
          loading={loading}
          variant='contained'
          color={buttonColor}
          fullWidth
          id={id}
          startIcon={buttonIcon}
          text={buttonText}
        />
      </DialogActions>
    </Dialog>
  )
}

export default BackendRequestDialog
