import { Box, Skeleton } from '@mui/material'
import React from 'react'

import Plot from 'react-plotly.js'
import { HighlightedSampleData } from '../prsResults/types'

const colors = [
  { lineColor: '#61a146', fillColor: '#d3eac8' },
  { lineColor: '#d5294d', fillColor: '#f7aab2' },
  { lineColor: '#f49d0c', fillColor: '#fde58a' },
  { lineColor: '#438e96', fillColor: '#bfe0e2' },
  { lineColor: '#d2643d', fillColor: '#eaba9f' },
  { lineColor: '#9173df', fillColor: '#e0dcf8' },
]

export type ViolinPlotData = {
  groupName: string
  y: number[]
  yText: string[]
}

type Props = {
  data: ViolinPlotData[]
  isLoading: boolean
  controlClass: string
  highlightedSampleData?: HighlightedSampleData
  dialogMode: boolean
}

const ViolinPlot: React.FC<Props> = (props) => {
  const groupsData = props.data.filter((group) =>
    group.y.some((value) => value !== undefined)
  )
  const controlGroupLineColors = new Map<string, string>()
  const highlighted_patient_color = 'blue'

  const data = groupsData.map((group, index) => {
    const marker_colors: string[] = []

    const groupLineColor = colors[index % colors.length].lineColor
    controlGroupLineColors.set(group.groupName, groupLineColor)
    // filter out undefined values and collect valid y values and their corresponding text
    const { y: filteredY, yText: filteredYText } = group.y.reduce<{
      y: number[]
      yText: string[]
    }>(
      (acc, value, idx) => {
        if (value !== undefined) {
          acc.y.push(value)
          acc.yText.push(group.yText[idx])
        }

        let marker_color = groupLineColor
        if (
          props.highlightedSampleData &&
          props.highlightedSampleData.yText === group.yText[idx]
        ) {
          marker_color = highlighted_patient_color
        }
        marker_colors.push(marker_color)
        return acc
      },
      { y: [], yText: [] }
    )
    return {
      type: 'violin' as const,
      y: filteredY,
      box: {
        visible: true,
      },
      meanline: {
        visible: true,
      },
      marker: {
        size: props.dialogMode ? 6 : 3,
        color: marker_colors, // Keep marker colors separate
      },
      fillcolor: colors[index % colors.length].fillColor,
      points: 'all',
      opacity: 0.8,
      text: filteredYText,
      x0: group.groupName,
    }
  })

  return !props.isLoading ? (
    <Plot
      data={data}
      layout={{
        xaxis: {
          title: 'Groups',
          type: 'category',
        },
        yaxis: {
          title: 'Values',
        },

        paper_bgcolor: 'transparent',
        plot_bgcolor: 'transparent',
        showlegend: false,
        shapes: props.highlightedSampleData
          ? [
              {
                type: 'line',
                xref: 'paper',
                yref: 'y',
                y0: props.highlightedSampleData.y,
                y1: props.highlightedSampleData.y,
                x0: 0,
                x1: 1,
                line: {
                  color:
                    controlGroupLineColors.get(
                      props.highlightedSampleData.patientClass
                    ) ?? 'black',
                  width: 2,
                  dash: 'dot',
                },
              },
            ]
          : [],
      }}
      style={{ width: '100%', height: '90%' }}
    />
  ) : (
    <Box width='100%' height='100%' p={3}>
      <Skeleton variant='rounded' height='90%' />
    </Box>
  )
}

export default ViolinPlot
